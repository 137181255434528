import { useAuth0 } from "@auth0/auth0-react";
import { DashCardLoaderSmall } from "../../../../components/LoaderC";
import styles from "../dashboard.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

const LeaseData = ({ selectedProperty }) => {
    const redirect = useHistory();
    const { getAccessTokenSilently } = useAuth0();
    const [requestData, setRequestData] = useState(0);
    const [contentLoading, setContentLoading] = useState(true);

    useEffect(() => {
        const fetchData = async (pId) => {
            setContentLoading(true);
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            if (pId > 0) {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-lease-status/" + pId, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    setContentLoading(false);
                    setRequestData(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                    setRequestData(0);
                });
            } else {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-lease-status", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    setContentLoading(false);
                    setRequestData(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                    setRequestData(0);
                });
            }
        }

        if (selectedProperty > 0) {
            fetchData(selectedProperty);
        } else {
            fetchData(0);
        }
    }, [selectedProperty, getAccessTokenSilently]);

    return (<div className={`${styles.InsideCard} ${styles.Size45}`}>
        {contentLoading && (<div className={`${styles.LoaderDash5}`}>
            <DashCardLoaderSmall />
        </div>)}

        {!contentLoading && (<div className={`${styles.InsideCardFlex}`} style={{ cursor: 'pointer' }} onClick={(e) => {
            localStorage.setItem("leaseStatusyFilterData", 5);
            redirect.push('/leases');
        }}>
            <div className={`${styles.PrograssIcon}`}>
                <img src="/static/img/LeaseIcon.svg" width="35px" height="35px" alt="" />
            </div>
            <div className={`${styles.InsideCardFlexCon}`}>
                <h3 className={`${styles.InsideCardTitleAmount}`}>{requestData}</h3>
                <h6 className={`${styles.InsideCardTitle}`}>Expiring Leases in 90 Days</h6>
            </div>
        </div>)}
    </div>);
}

export default LeaseData;