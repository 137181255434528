import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide, Snackbar, TextField } from "@mui/material";
import styles from "../../smartdevicedetails.module.css";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-smart-toaster";
import axios from 'axios';
import { Edit, Trash2, X } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const schema = yup.object().shape({
    startDate: yup.string().required("This field is Required").nullable(),
    endDate: yup.string().required("This field is Required").nullable(),
});

const TTDoorLockcontrollerFingerprint = ({ deviceDetails, setLoading }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [deleteModalOpen2, setDeleteModalOpen2] = useState(false);
    const [deleteId2, setDeleteId2] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [fingerPrintList, setFingerPrintList] = useState([]);
    const [editId, setEditId] = useState(0);
    const [editData, setEditData] = useState(null);

    const { handleSubmit, control, errors, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
    });

    useEffect(() => {
        const getList = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            await axios.get(process.env.REACT_APP_DEVICE_API_URL + "v1/ttlock/get-finger-prints/" + deviceDetails?.id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                setFingerPrintList(response.data.data);
            }).catch((error) => {
                setFingerPrintList([]);
            });
        }

        if (deviceDetails?.id) {
            getList();
        }

    }, [deviceDetails, getAccessTokenSilently]);

    const deletePassword2 = async () => {
        setLoading(true);
        setDeleteModalOpen2(false);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.delete(`${process.env.REACT_APP_DEVICE_API_URL}v1/ttlock/delete-finger-prints/${deviceDetails?.id}/${deleteId2}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);
            setSnackbarMsg(response?.data?.message);
            setSnackbarOpen(true);

            setFingerPrintList(prev => {
                let newArr = prev?.filter(i => i?.fingerprintId !== deleteId2);
                return newArr;
            });

            setDeleteId2(0);
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const onUpdate = async (data) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        let postData = { startDate: dayjs(data?.startDate).valueOf(), endDate: dayjs(data?.endDate).valueOf() };

        axios.post(`${process.env.REACT_APP_DEVICE_API_URL}v1/ttlock/update-finger-prints/${deviceDetails?.id}/${editId}`, postData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);

            setSnackbarMsg(response?.data?.message);
            setSnackbarOpen(true);

            setEditData(null);

            setFingerPrintList(prev => {
                let newArr = prev.map(i => {
                    if (i?.fingerprintId === editId) {
                        return { ...i, startDate: postData?.startDate, endDate: postData?.endDate };
                    }
                    return i;
                });
                return newArr;
            });


        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    useEffect(() => {
        if (editData) {
            setValue('startDate', dayjs(editData?.startDate));
            setValue('endDate', dayjs(editData?.endDate));
        }
    }, [editData, setValue]);


    return (<>
        <div className={`${styles.cardUnderS}`}>
            {deviceDetails?.online === 0 && (<div className={`${styles.OfflineSection}`}>
                <img src="/static/img/offline.svg" width="60px" height="60px" alt="" />
                <p>Device Is Offline</p>
            </div>)}

            <div className={`${styles.WidgetIndi}`}>
                {!fingerPrintList?.length > 0 && <div className={`${styles.NoPinSec}`}>
                    <div>
                        <img src="/static/img/noDoorPin.svg" alt="" />
                        <p>No Fingerprints are Available</p>
                    </div>
                </div>}

                {!editData && <div className={`${styles.PinCodeListArea}`}>
                    {fingerPrintList?.map(i => {
                        return (<div className={`${styles.PinCodeList}`}>
                            <div className={`${styles.PinCodeListTitleSec}`}>
                                <p className={`${styles.PinCodeListTitle}`}>{i?.fingerprintName}</p>
                                <div>
                                    {i?.type !== 1 && <button onClick={(e) => { setEditId(i?.fingerprintId); setEditData(i); }}><Edit /></button>}
                                    <button onClick={(e) => { setDeleteModalOpen2(true); setDeleteId2(i?.fingerprintId); }}><Trash2 /></button>
                                </div>
                            </div>
                        </div>);
                    })}
                </div>}

                {editData && <div className={`${styles.Full}`}>
                    <form onSubmit={handleSubmit(onUpdate)}>
                        <p className={`${styles.PinCodeListTitle}`}>Update Time Period of {editData?.fingerprintName}</p>
                        <div className={`${styles.PinDatePicker} FormGroup PinTypeSec`}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="startDate"
                                    control={control}
                                    render={(field) => (
                                        <DatePicker
                                            {...field}
                                            label="Start Date*"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            {errors.startDate && (<p className={`${styles.ErrorM}`}>{errors?.startDate?.message}</p>)}
                        </div>
                        <div className={`${styles.PinDatePicker} FormGroup PinTypeSec`}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="endDate"
                                    control={control}
                                    render={(field) => (
                                        <DatePicker
                                            {...field}
                                            label="End Date*"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            {errors.endDate && (<p className={`${styles.ErrorM}`}>{errors?.endDate?.message}</p>)}
                        </div>
                        <div className={`${styles.ButtonAreaPinCode}`}>
                            <button type="button" onClick={(e) => { setEditId(0); setEditData(null); }} className={`${styles.ButtonVoiletLine}`}>Cancel</button>
                            <button type="submit" className={`${styles.ButtonVoiletSolid}`}>Update</button>
                        </div>
                    </form>
                </div>}
            </div>
        </div>

        <Dialog
            open={deleteModalOpen2}
            fullWidth
            maxWidth="sm"
            onClose={(e) => setDeleteModalOpen2(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete the fingerprint? <button onClick={(e) => setDeleteModalOpen2(false)}><X /></button></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">The fingerprint will be removed immediately. You can't undo this action.</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => deletePassword2()} className="CancelPopupBU">Delete</Button>
                <Button onClick={(e) => setDeleteModalOpen2(false)} className="SubmitPopupBU">Cancel</Button>
            </DialogActions>
        </Dialog>

        <Snackbar
            open={snackbarOpen}
            onClose={(e) => setSnackbarOpen(false)}
            TransitionComponent={Slide}
            message={snackbarMsg}
            autoHideDuration={10000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            action={
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => setSnackbarOpen(false)}
                >
                    <X />
                </IconButton>
            }
        />

    </>);
}

export default TTDoorLockcontrollerFingerprint;