export default function conversationIdReducer (id = "", actions) {

    switch (actions.type) {

        case 'set':
            return id =  actions.payload;
            case 'reset':
                return id = "";

        default:
            return id
    }

}