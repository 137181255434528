import { useEffect, useState } from "react";
import styles from "../dashboard.module.css";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { DashCardLoader } from "../../../../components/LoaderC";
import PubNubChannel from "../../../smartdevices/components/pubnub/pubnub-channel";
import moment from "moment";

const SmartDeviceAlerts = ({ selectedProperty, filterDateRange }) => {
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [alertList, setAlertList] = useState([]);
  const [openAlertCount, setOpenAlertCount] = useState(0);
  const [contentLoading, setContentLoading] = useState(true);
  const [ownerId, setOwnerId] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      let userMetadata = user["https://propertydek.com/user_metadata"];
      setOwnerId(userMetadata?.ownerID);
    }
  }, [user, isAuthenticated])

  useEffect(() => {
    const fetchData = async (pId) => {
      setContentLoading(true);
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      let params = {};

      if (filterDateRange?.start && filterDateRange?.end) {
        params = { start: filterDateRange?.start, end: filterDateRange?.end };
      }

      if (pId > 0) {
        await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-device-alert-list/" + pId, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params
        }).then((response) => {
          setContentLoading(false);
          setAlertList(response.data.data?.list);
          setOpenAlertCount(response.data.data?.alert_counts);
        }).catch((error) => {
          setContentLoading(false);
          setAlertList([]);
          setOpenAlertCount(0);
        });
      } else {
        await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-device-alert-list", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params
        }).then((response) => {
          setContentLoading(false);
          setAlertList(response.data.data?.list);
          setOpenAlertCount(response.data.data?.alert_counts);
        }).catch((error) => {
          setContentLoading(false);
          setAlertList([]);
          setOpenAlertCount(0);
        });
      }
    }

    if (selectedProperty > 0) {
      fetchData(selectedProperty);
    } else {
      fetchData(0);
    }
  }, [selectedProperty, filterDateRange, getAccessTokenSilently]);

  const updateDeviceStatus = (deviceId, deviceStatus, bizCode, data) => {
    try {
      if (!data) {
        return;
      }
      const timeDistance = moment(data.created_at).fromNow();
      data.time_str = `${timeDistance}`;

      if (deviceId) {
        let alertListTemp = [data, ...alertList];
        alertListTemp.pop();

        setAlertList(alertListTemp);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (<>
    <div className={`${styles.CaseDiv} ${styles.CardSmartDe}`}>
      <div className={`${styles.IfnoCard}`}>
        <div className={`${styles.InsideCard}`}>


          {contentLoading && (<div className={`${styles.LoaderDash2}`}>
            <DashCardLoader />
          </div>)}

          {!contentLoading && (<>
            <div className={`${styles.HeadFlex}`}>
              <h6 className={`${styles.InsideCardTitle2}`}>Device Alerts {openAlertCount > 0 && <span>{openAlertCount} Open</span>}</h6>
              <Link to="/smart-devices/alerts" className={`${styles.ViewAllBU}`}>View All</Link>
            </div>
            <div className={`${styles.ScrollCard}`}>
              {alertList?.map((item) => {
                return (<Link to={"/smart-devices/details/" + item?.device_id} key={item?.id}>
                  <div className={`${styles.CaseCard}`}>
                    {item?.title === "Water Leak" && (<div className={`${styles.DeviceAlertIcon} ${styles.Blue}`}>
                      <img src="/static/img/water-dash.svg" width="24px" height="24px" alt="" />
                    </div>)}
                    {item?.title === "Low Battery" && (<div className={`${styles.DeviceAlertIcon} ${styles.Orange}`}>
                      <img src="/static/img/battery-dash.svg" width="24px" height="24px" alt="" />
                    </div>)}
                    {item?.title === "Device Offline" && (<div className={`${styles.DeviceAlertIcon} ${styles.Red}`}>
                      <img src="/static/img/wifi-dash.svg" width="24px" height="24px" alt="" />
                    </div>)}
                    {(item?.title?.toLowerCase().includes('door unlocked') || item?.title?.toLowerCase().includes('door is unlocked') || item?.title?.toLowerCase().includes('door was unlocked')) && (<div className={`${styles.DeviceAlertIcon} ${styles.Green}`}>
                      <img src="/static/img/lock-dash.svg" width="24px" height="24px" alt="" />
                    </div>)}
                    <div className={`${styles.DeviceAlertTT}`}>
                      <div className={`${styles.CaseCardTitle}`}>
                        <Tooltip title={item?.title} placement="bottom-start">
                          <p className={`${styles.TitleText} ${styles.DeviceTextWrap}`}>{item?.title}</p>
                        </Tooltip>
                        <p className={`${styles.DateText}`}>{item?.time_str}</p>
                      </div>
                      <p className={`${styles.TitleName}`}>{item?.apt_no ? item?.apt_no : item?.apt_text}, {item?.product_type}</p>
                    </div>
                  </div>
                </Link>);
              })}
              {alertList?.length === 0 && (<div className={`${styles.NoDataSec}`}>
                <div className={`${styles.NoDataIMG}`}><img src="/static/img/no-device.png" alt="" /></div>
                <p className={`${styles.NoDataText}`}>Don't Worry!</p>
                <p className={`${styles.NoDataTextSub}`}>Device Alerts is empty.</p>
              </div>)}
            </div>
          </>)}
        </div>
      </div>
    </div>
    {ownerId && (<PubNubChannel ownerId={ownerId} updateDeviceStatus={updateDeviceStatus}></PubNubChannel>)}
  </>);
};

export default SmartDeviceAlerts;