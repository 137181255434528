
const setConversationId = (id)=> {

    return {
        type: 'set',
        payload: id
        
    }
}
const resetConversationId = ()=> {

    return {
        type: 'reset',
        
        
    }
}

export  {resetConversationId,setConversationId}