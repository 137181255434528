import styles from "../dashboard.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { DashCardLoaderSmall } from "../../../../components/LoaderC";

const MonthCollection = ({ selectedProperty, filterDateRange }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [requestData, setRequestData] = useState(null);
    const [contentLoading, setContentLoading] = useState(true);

    useEffect(() => {
        const fetchData = async (pId) => {
            setContentLoading(true);
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            let params = {};

            if (filterDateRange?.start && filterDateRange?.end) {
                params = { start: filterDateRange?.start, end: filterDateRange?.end };
            }

            if (pId > 0) {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-payment-collections/" + pId, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: params
                }).then((response) => {
                    setContentLoading(false);
                    setRequestData(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                    setRequestData(0);
                });
            } else {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-payment-collections", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: params
                }).then((response) => {
                    setContentLoading(false);
                    setRequestData(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                    setRequestData(0);
                });
            }
        }

        if (selectedProperty > 0) {
            fetchData(selectedProperty);
        } else {
            fetchData(0);
        }
    }, [selectedProperty, filterDateRange, getAccessTokenSilently]);

    return (<div className={`${styles.InsideCard} ${styles.Size55}`}>
        {contentLoading && (<div className={`${styles.LoaderDash5}`}>
            <DashCardLoaderSmall />
        </div>)}

        {!contentLoading && (<div className={`${styles.InsideCardFlex} ${styles.Flex}`}>
            <h6 className={`${styles.InsideCardTitle}`}>Rent Collected This Month</h6>
            <div className={`${styles.InsideCardFlexCon}`}>
                <h3 className={`${styles.InsideCardTitleAmount}`}>
                    ${requestData?.received_f}
                    {requestData?.total > 0 && (<span>{(requestData?.received / requestData?.total) * 100 > 100 ? 100 : ((requestData?.received / requestData?.total) * 100).toFixed(1)}%</span>)}
                </h3>
                {requestData?.total > 0 && (<div className={`${styles.PrograssDiv}`}>
                    {requestData?.received_p > 0 && (<div style={{ width: `${requestData?.received_p}%` }} className={`${styles.PrograssDivCollected}`}>
                        <div style={{ background: "#5AC55E", height: "7px", borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px" }}></div>
                        <div className={`${styles.ProgressDivHoverBox} ${styles.Collected}`}>
                            <div></div>
                            <p>Rent Collected: ${requestData?.received_f}</p>
                        </div>
                    </div>)}
                    {requestData?.pending_p > 0 && (<div style={{ width: `${requestData?.pending_p}%` }} className={`${styles.PrograssDivProcessing}`}>
                        <div style={{ background: "#FFBC00", height: "7px" }}></div>
                        <div className={`${styles.ProgressDivHoverBox} ${styles.Processing}`}>
                            <div></div>
                            <p>Rent Processing: ${requestData?.pending_f}</p>
                        </div>
                    </div>)}

                    {requestData?.due_p > 0 && (<div style={{ width: `${requestData?.due_p}%` }} className={`${styles.PrograssDivDue}`}>
                        <div style={{ background: "#EAECEF", height: "7px", borderTopRightRadius: "4px", borderBottomRightRadius: "4px" }}></div>
                        <div className={`${styles.ProgressDivHoverBox} ${styles.Due}`}>
                            <div></div>
                            <p>Rent Due: ${requestData?.due_f}</p>
                        </div>
                    </div>)}
                </div>)}
            </div>
        </div>)}
    </div>);
}

export default MonthCollection;