import { IconButton, Slide, Slider, Snackbar } from "@mui/material";
import styles from "../../smartdevicedetails.module.css";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SmartDeviceDetailsService from "../../smartdevicedetails.service";
import { toast } from "react-smart-toaster";
import { X } from "react-feather";

const TTDoorLockcontroller = ({ ttLockDeviceData, deviceDetails }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [showUnlockIcon, setShowUnlockIcon] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [unlockValue, setUnloackValue] = useState(0);

    const onDoorLockChange = async (e, v) => {
        if (v !== 10) {
            return;
        }

        setShowUnlockIcon(true);

        try {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            const headers = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await SmartDeviceDetailsService.OnUnlockTTLock(deviceDetails?.deviceID, headers);

            if (response.status === 200) {
                setSnackbarMsg(response?.data?.message);
                setSnackbarOpen(true);

                setTimeout(() => {
                    setShowUnlockIcon(false);
                    setUnloackValue(0);
                }, 4000);
            } else {
                setShowUnlockIcon(false);
                toast.error("Door Lock Device Error");
                setUnloackValue(0);
            }
        } catch (error) {
            setShowUnlockIcon(false);
            toast.error(error?.response?.data?.message);
        }
    }

    return (<>
        <div className={`${styles.cardUnderS}`}>
            {deviceDetails?.online === 0 && (<div className={`${styles.OfflineSection}`}>
                <img src="/static/img/offline.svg" width="60px" height="60px" alt="" />
                <p>Device Is Offline</p>
            </div>)}
            <div className={`${styles.WidgetIndi} ${styles.Doorlock}`}>
                <div className={`${styles.HeadIcon}`}>
                    <img src="/static/img/battery.png" alt="" />
                </div>
                <div>
                    <p className={`${styles.BatteryT}`}>{ttLockDeviceData?.electric_quantity}%</p>
                    <p className={`${styles.BatteryTT}`}>Battery</p>
                </div>
            </div>
            <div className={`${styles.WidgetIndi}`}>
                <p className={`${styles.WidgetT}`}>Unlock Door</p>
                {!showUnlockIcon && (<div className="DoorLockWidget">
                    <Slider
                        className="DoorLock"
                        value={unlockValue}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="off"
                        step={1}
                        marks
                        min={0}
                        max={10}
                        onChange={(e, v) => setUnloackValue(v)}
                        onChangeCommitted={onDoorLockChange}
                    />
                    <button className="DoorlockSBU">
                        <img src="/static/img/lock-line.svg" alt="" />
                    </button>
                </div>)}
                {showUnlockIcon && (<div className={`${styles.DoorLockedIcon} ${styles.Green}`}>
                    <img src="/static/img/lock-dash.svg" width="28px" height="28px" alt="" />
                </div>)}
            </div>

        </div>

        <Snackbar
            open={snackbarOpen}
            onClose={(e) => setSnackbarOpen(false)}
            TransitionComponent={Slide}
            message={snackbarMsg}
            autoHideDuration={10000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            action={
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => setSnackbarOpen(false)}
                >
                    <X />
                </IconButton>
            }
        />

    </>);
}

export default TTDoorLockcontroller;