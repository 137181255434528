import { useEffect, useState } from "react";
import styles from "../dashboard.module.css";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { DashCardLoaderSmall } from "../../../../components/LoaderC";
import { ArrowDown, ArrowUp } from "react-feather";

const OccupiedUnits = ({ selectedProperty }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [requestData, setRequestData] = useState(null);
    const [contentLoading, setContentLoading] = useState(true);

    useEffect(() => {
        const fetchData = async (pId) => {
            setContentLoading(true);
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            if (pId > 0) {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-units-status/" + pId, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    setContentLoading(false);
                    setRequestData(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                    setRequestData(null);
                });
            } else {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-units-status", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    setContentLoading(false);
                    setRequestData(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                    setRequestData(null);
                });
            }
        }

        if (selectedProperty > 0) {
            fetchData(selectedProperty);
        } else {
            fetchData(0);
        }
    }, [selectedProperty, getAccessTokenSilently]);

    return (<div className={`${styles.ScoreCards}`}>
        {contentLoading && (<div className={`${styles.LoaderDash3}`}>
            <div><DashCardLoaderSmall /></div>
        </div>)}

        {!contentLoading && (<div className={`${styles.ScoreCardsFull}`}>
            <div className={`${styles.ScoreCardsUnder}`}>
                <div className={`${styles.ScoreCardCon}`}>
                    <p className={`${styles.ScoeCardTitle}`}># of Occupied Units</p>
                    <div className={`${styles.ScorePrograssBar}`}>
                        <div className={`${styles.PrograssScore}`} style={{ width: requestData ? requestData?.occupied_percentage + '%' : '0%' }}></div>
                    </div>
                    <div className={`${styles.ScoreMiddleArea}`}>
                        <p className={`${styles.ScoeCardCon}`}>{requestData ? requestData?.occupied_percentage : 0}%</p>
                        <div className={(requestData && requestData?.is_increase === 1) ? `${styles.ScoreUpDOwnArrow} ${styles.Green}` : `${styles.ScoreUpDOwnArrow} ${styles.Red}`}>{(requestData && requestData?.is_increase === 1) ? <ArrowUp /> : <ArrowDown />}</div>
                        <p className={`${styles.ScoeCardConTwo}`}>{requestData ? requestData?.occupied : 0} / {requestData ? requestData?.total : 0}</p>
                    </div>
                    <p className={`${styles.ScoreCardLMsg}`}><span>{requestData ? requestData?.last_30_days : 0}</span> of new tenants the last 30 days</p>
                </div>
            </div>
        </div>)}
    </div>);
}

export default OccupiedUnits;