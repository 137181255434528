import { ChevronRight } from "react-feather";
import styles from "../dashboard.module.css";
import { useEffect, useState } from "react";
import MonthlyCollectionList from "./MonthlyCollectionList";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { DashCardLoaderSmall } from "../../../../components/LoaderC";

const MonthCollection = ({ selectedProperty, filterDateRange }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [requestData, setRequestData] = useState(null);
    const [contentLoading, setContentLoading] = useState(true);
    const [openDuePaymentList, setOpenDuePaymentList] = useState(false);
    const [openLatePaymentList, setOpenLatePaymentList] = useState(false);
    const [openPrePaymentList, setOpenPrePaymentList] = useState(false);

    useEffect(() => {
        const fetchData = async (pId) => {
            setContentLoading(true);
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            let params = {};

            if (filterDateRange?.start && filterDateRange?.end) {
                params = { start: filterDateRange?.start, end: filterDateRange?.end };
            }

            if (pId > 0) {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-late-payment-list/" + pId, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: params
                }).then((response) => {
                    setContentLoading(false);
                    setRequestData(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                    setRequestData(0);
                });
            } else {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-late-payment-list", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: params
                }).then((response) => {
                    setContentLoading(false);
                    setRequestData(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                    setRequestData(0);
                });
            }
        }

        if (selectedProperty > 0) {
            fetchData(selectedProperty);
        } else {
            fetchData(0);
        }
    }, [selectedProperty, filterDateRange, getAccessTokenSilently]);

    return (<>
        {contentLoading && (<div className={`${styles.LoaderDash4}`}>
            <DashCardLoaderSmall />
        </div>)}

        {!contentLoading && (<>
            <div className={`${styles.InsideCard} ${styles.FullSize}`} onClick={(e) => setOpenDuePaymentList(true)}>
                <div className={`${styles.InsideCardFlex} ${styles.Flex}`}>
                    <h6 className={`${styles.InsideCardTitle}`}>Payments Due</h6>
                    <div className={`${styles.InsideCardFlexConTwo}`}>
                        <h3 className={`${styles.InsideCardTitleAmount}`} style={{ fontSize: '28px' }}>${requestData?.due_amount_f}</h3>
                    </div>
                    <ChevronRight className={`${styles.ActionArrow}`} />
                </div>
            </div>
            <div className={`${styles.InsideCard} ${styles.FullSize}`} onClick={(e) => setOpenLatePaymentList(true)}>
                <div className={`${styles.InsideCardFlex} ${styles.Flex}`}>
                    <h6 className={`${styles.InsideCardTitle}`}>Late Payments</h6>
                    <div className={`${styles.InsideCardFlexConTwo}`}>
                        <h3 className={`${styles.InsideCardTitleAmount}`} style={{ fontSize: '28px' }}>${requestData?.late_payment_f}</h3>
                    </div>
                    <ChevronRight className={`${styles.ActionArrow}`} />
                </div>
            </div>
            <div className={`${styles.InsideCard} ${styles.FullSize}`} onClick={(e) => setOpenPrePaymentList(true)}>
                <div className={`${styles.InsideCardFlex} ${styles.Flex}`}>
                    <h6 className={`${styles.InsideCardTitle}`}>Pre-payments for next month</h6>
                    <div className={`${styles.InsideCardFlexConTwo}`}>
                        <h3 className={`${styles.InsideCardTitleAmount}`} style={{ fontSize: '28px' }}>${requestData?.pre_payment_f}</h3>
                    </div>
                    <ChevronRight className={`${styles.ActionArrow}`} />
                </div>
            </div>
        </>)}

        <MonthlyCollectionList openedRentCard={openDuePaymentList} setOpenedRentCard={setOpenDuePaymentList.bind(this)} title={'Payments Due'} total={requestData?.due_amount_f} data={requestData?.due_amounts?.map(i => { return { 'apt_name': i?.apt_name, 'tenant_name': i?.tenant_name, 'amount': i?.formatted_due_amount }; })} />
        <MonthlyCollectionList openedRentCard={openLatePaymentList} setOpenedRentCard={setOpenLatePaymentList.bind(this)} title={'Late Payments'} total={requestData?.late_payment_f} data={requestData?.late_payments?.map(i => { return { 'apt_name': i?.apt_name, 'tenant_name': i?.tenant_name, 'amount': i?.formatted_late_payment_amount }; })} />
        <MonthlyCollectionList openedRentCard={openPrePaymentList} setOpenedRentCard={setOpenPrePaymentList.bind(this)} title={'Pre Payments'} total={requestData?.pre_payment_f} data={requestData?.pre_payments?.map(i => { return { 'apt_name': i?.apt_name, 'tenant_name': i?.tenant_name, 'amount': i?.formatted_advanced_amount }; })} />
    </>);
}

export default MonthCollection;