import { useEffect, useState } from "react";
import styles from "../dashboard.module.css";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { DashCardLoaderSmall } from "../../../../components/LoaderC";

const ReceivedMessages = ({ selectedProperty }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [requestData, setRequestData] = useState(0);
    const [contentLoading, setContentLoading] = useState(true);

    useEffect(() => {
        const fetchData = async (pId) => {
            setContentLoading(true);
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            if (pId > 0) {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-received-messages/" + pId, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    setContentLoading(false);
                    setRequestData(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                    setRequestData(null);
                });
            } else {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-received-messages", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    setContentLoading(false);
                    setRequestData(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                    setRequestData(null);
                });
            }
        }

        if (selectedProperty > 0) {
            fetchData(selectedProperty);
        } else {
            fetchData(0);
        }
    }, [selectedProperty, getAccessTokenSilently]);

    return (<div className={`${styles.ScoreCardsTwo}`}>
        {contentLoading && (<div className={`${styles.LoaderDash3}`}>
            <div><DashCardLoaderSmall /></div>
        </div>)}

        {!contentLoading && (<div className={`${styles.ScoreCardsFull}`}>
            <div className={`${styles.ScoreCardsUnder}`}>
                <div className={`${styles.ScoreCardCon}`}>
                    <p className={`${styles.ScoeCardConThree}`}>{requestData}</p>
                    <p className={`${styles.ScoeCardTitleTwo}`}>of Messages Received</p>
                </div>
            </div>
        </div>)}
    </div>);
}

export default ReceivedMessages;