import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, TablePagination } from "@mui/material";
import styles from "../../tenantdetails.module.css";
import { ArrowLeft, ChevronDown, Filter, Plus, Search, X } from "react-feather";
import { Dropdown } from "react-bootstrap";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useEffect, useState } from "react";
import moment from "moment";
import AddBill from "../../../payment/paymentcomps/AddBill";
import RecordPayment from "../../../payment/paymentcomps/RecordPayment";
import AddCredit from "../../../payment/paymentcomps/AddCredit";

const PaymentsTab = ({ tenantData, setTenantData, setLoading, setSnackbarOpen, setSnackbarMsg }) => {
    const [page, setPage] = useState(0);
    const [paymentList, setPaymentList] = useState([]);
    const [filterKey, setFilterKey] = useState("");
    const [expanded2, setExpanded2] = useState(false);
    const [paymentFilterType, setPaymentFilterType] = useState({ bill: true, credit: true, payment: true });
    const [paymentFilterText, setPaymentFilterText] = useState("All");
    const [perPage, setPerPage] = useState(5);
    const [addBillModalShow, setAddBillModalShow] = useState(false);
    const [addCreditModalShow, setAddCreditModalShow] = useState(false);
    const [addPaymentModalShow, setAddPaymentModalShow] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [editId, setEditId] = useState(0);

    useEffect(() => {
        if (tenantData?.payments) {
            let paymentTempList = tenantData?.payments;

            paymentTempList = paymentTempList.sort(function (a, b) {
                if (a.date_new === b.date_new) {
                    return a.id - b.id;
                } else {
                    return new Date(a.date_new) - new Date(b.date_new);
                }
            });

            let balanceNew = 0;
            paymentTempList = paymentTempList?.map(i => {
                let dueAmount = 0;
                let payAmount = 0;
                if (i?.type === 1) {
                    dueAmount = parseFloat(i?.amount);
                    balanceNew += parseFloat(i?.amount);
                }
                if (i?.type === 2) {
                    dueAmount = parseFloat(balanceNew);
                    balanceNew -= parseFloat(i?.amount);
                    payAmount = parseFloat(i?.amount);
                }
                let FdueAmount = dueAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD', });
                let FbalanceNew = balanceNew.toLocaleString('en-US', { style: 'currency', currency: 'USD', });
                let FpayAmount = payAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD', });
                return { ...i, due_amount_new: FdueAmount, balance_amount_new: FbalanceNew, payment_amount_new: FpayAmount };
            });

            paymentTempList = paymentTempList.reverse();

            if (filterKey !== "") {
                let inputVal = filterKey.toLowerCase();
                paymentTempList = paymentTempList.filter((item) => {
                    return (item.description.toLowerCase().includes(inputVal) || item.formatted_amount.toLowerCase().includes(inputVal));
                });
            }

            let payfilterNo = 0;
            if (paymentFilterType?.bill) {
                payfilterNo++;
            } else {
                paymentTempList = paymentTempList.filter(i => i?.type !== 1);
            }

            if (paymentFilterType?.credit) {
                payfilterNo++;
            } else {
                paymentTempList = paymentTempList.filter(i => i?.type !== 2 || i?.payment_type !== 'add-credit');
            }

            if (paymentFilterType?.payment) {
                payfilterNo++;
            } else {
                paymentTempList = paymentTempList.filter(i => i?.type !== 2 || i?.payment_type === 'add-credit');
            }
            if (payfilterNo >= 3) {
                setPaymentFilterText("All");
            } else {
                setPaymentFilterText(payfilterNo + " Selected");
            }


            setPaymentList(paymentTempList);
        }
    }, [tenantData, filterKey, paymentFilterType]);

    const addData = (data) => {
        setTenantData(prev => {
            return { ...prev, payments: [data].concat(prev.payments) };
        });
    }

    const updateData = (data) => {
        console.log('updateData data');
        console.log(data);
    }

    return <>
        <div className={`${styles.NotesSearchArea}`}>
            <div className={`${styles.FilterSearchSec}`}>
                <div className={`${styles.NotesSearchMain}`}>
                    <Search />
                    <input type="text" placeholder="Search" className={`${styles.searchInput}`} onChange={(e) => setFilterKey(e.target.value)} />
                </div>
                <Dropdown>
                    <Dropdown.Toggle className={`${styles.ActionsBULine} ActionsBULine`} id="dropdown-basic">
                        <span className={`${styles.ReqBigFilter}`}>Show: {paymentFilterText} <ChevronDown /></span>
                        <span className={`${styles.ReqSmallFilter}`}><Filter /></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                        <FormGroup row className={`${styles.CheckFilter}`}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="All"
                                        color="primary"
                                        checked={paymentFilterType?.bill || paymentFilterType?.credit || paymentFilterType?.payment}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setPaymentFilterType({ bill: true, credit: true, payment: true });
                                            } else {
                                                setPaymentFilterType({ bill: false, credit: false, payment: false });
                                            }
                                        }}
                                    />
                                }
                                label="All"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="bill"
                                        color="primary"
                                        checked={paymentFilterType?.bill}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setPaymentFilterType(prev => {
                                                    return { ...prev, bill: true };
                                                });
                                            } else {
                                                setPaymentFilterType(prev => {
                                                    return { ...prev, bill: false };
                                                });
                                            }
                                        }}
                                    />
                                }
                                label="Bills"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="credit"
                                        color="primary"
                                        checked={paymentFilterType?.credit}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setPaymentFilterType(prev => {
                                                    return { ...prev, credit: true };
                                                });
                                            } else {
                                                setPaymentFilterType(prev => {
                                                    return { ...prev, credit: false };
                                                });
                                            }
                                        }}
                                    />
                                }
                                label="Credits"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="checkedH"
                                        color="primary"
                                        checked={paymentFilterType?.payment}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setPaymentFilterType(prev => {
                                                    return { ...prev, payment: true };
                                                });
                                            } else {
                                                setPaymentFilterType(prev => {
                                                    return { ...prev, payment: false };
                                                });
                                            }
                                        }}
                                    />
                                }
                                label="Payments"
                            />
                        </FormGroup>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <Dropdown>
                <Dropdown.Toggle className={`${styles.ActionsBU} ${styles.Red} ActionsBU Red`} id="dropdown-basic">
                    <Plus /> <span className={`${styles.AddBuText}`}>New</span>
                </Dropdown.Toggle>

                <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                    <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { setAddBillModalShow(true); setIsUpdate(false); setEditId(0); }}>
                        <img src="/static/img/bill-icon.svg" alt="" />Bill
                    </Dropdown.Item>
                    <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { setAddCreditModalShow(true); setIsUpdate(false); setEditId(0); }}>
                        <img src="/static/img/credit-icon.svg" alt="" />Credit
                    </Dropdown.Item>
                    <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { setAddPaymentModalShow(true); setIsUpdate(false); setEditId(0); }}>
                        <img src="/static/img/bank-icon.svg" alt="" />Payment
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>

        {paymentList.length > 0 && (
            <div className={`${styles.RentRollListSec}`}>
                <div className="FormAccording TenantDetails Theme Shadow">
                {paymentList.map((itemP, index) => {
                    return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<Accordion expanded={expanded2 === `panell${index}`} onChange={(e, t) => setExpanded2(t ? `panell${index}` : false)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">                            
                            <div className={`${styles.NotesCard2}`}>
                                <div className={`${styles.AlertCardFirstSec}`}>
                                    <div className={`${styles.AccoTitleHead}`}>                                    
                                        <div className={`${styles.CardStatusSec}`}>
                                            <p className={`${styles.DateInfoAcc}`}>
                                                {itemP?.type === 1 && <img src="/static/img/bill-icon.svg" alt="" className={`${styles.StatusIcon}`} />}
                                                {itemP?.type === 2 && itemP?.payment_type !== "online-payment" && <img src="/static/img/credit-icon.svg" alt="" className={`${styles.StatusIcon}`} />}
                                                {itemP?.type === 2 && itemP?.payment_type === "online-payment" && <img src="/static/img/bank-icon.svg" alt="" className={`${styles.StatusIcon}`} />}
                                                <div>
                                                    {itemP?.type === 1 && <p className={`${styles.DueOnBadge}`}>Bill Due On</p>}
                                                    {(itemP?.type === 2 && itemP.payment_type === "add-credit") && <p className={`${styles.CreditedBadge}`}>Credit Applied On</p>}
                                                    {(itemP?.type === 2 && itemP.payment_type === "record-payment") && <p className={`${styles.CreditedBadge}`}>Payment Applied On</p>}
                                                    {(itemP?.type === 2 && itemP?.payment_type === "online-payment" && itemP?.payment_status === 0) && <p className={`${styles.PendingBadge}`}>Payment Sent: Pending</p>}
                                                    {(itemP?.type === 2 && itemP?.payment_type === "online-payment" && itemP?.payment_status === 1) && <p className={`${styles.CreditedBadge}`}>Payment Sent: Completed</p>}
                                                    {(itemP?.type === 2 && itemP?.payment_type === "online-payment" && itemP?.payment_status === 2) && <p className={`${styles.FailedBadge}`}>Payment Sent: Failed</p>}
                                                    <p className={`${styles.BadgeDate}`}>{moment(itemP.date).format("LL")}</p>
                                                </div>
                                            </p>
                                        </div>                                            
                                        <div className={`${styles.CardDesSec}`}>
                                            <p className={`${styles.DateInfo}`}>{itemP.description}</p>
                                        </div>
                                        <div className={`${styles.CardAmountSec}`}>
                                            <div>
                                                <p className={`${styles.DateInfo}`}><span>Due:</span>{itemP?.due_amount_new}</p>
                                            </div>
                                            <div>
                                                <p className={`${styles.DateInfo}`}>
                                                    <span>Payment:</span>
                                                    {itemP?.type === 2 ? itemP?.payment_amount_new : '-'}
                                                </p>
                                            </div>
                                            <div>
                                                <p className={`${styles.DateInfo}`}><span>Balance:</span>{itemP?.balance_amount_new}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={`${styles.CussAccoRow}`}>
                            <div className={`${styles.AccoMainSec}`}>
                                <ul>
                                    <li className={`${styles.CardDesList}`}>
                                        <p className={`${styles.AlertText}`}>
                                            <span>Description:</span> {itemP.description}
                                        </p>
                                    </li>
                                    {itemP?.type === 1 && <li>
                                        <p className={`${styles.AlertText}`}>
                                            <span>Invoice No</span> {(100000 + parseInt(itemP.id))}
                                        </p>
                                    </li>}
                                    {itemP.payment_type === "online-payment" && <li>
                                        <p className={`${styles.AlertText}`}>
                                            <span>Payer</span> {itemP?.payment_details?.payer}
                                        </p>
                                    </li>}
                                    {itemP.payment_type === "online-payment" && <li>
                                        <p className={`${styles.AlertText}`}>
                                            <span>Memo</span> {itemP?.payment_details?.memo}
                                        </p>
                                    </li>}
                                    {itemP.payment_type === "online-payment" && <li>
                                        <p className={`${styles.AlertText}`}>
                                            <span>Amount</span> {itemP?.payment_details?.formatted_amount}
                                        </p>
                                    </li>}
                                    <li>
                                        <p className={`${styles.AlertText}`}>
                                            <span>Type</span> {itemP?.recurring_type}
                                        </p>
                                    </li>
                                    {itemP.payment_type === "online-payment" && <li>
                                        <p className={`${styles.AlertText}`}>
                                            <span>Status</span> {itemP?.payment_details?.status}
                                        </p>
                                    </li>}
                                    {itemP.payment_type === "online-payment" && <li>
                                        <p className={`${styles.AlertText}`}>
                                            <span>Estimated deposit date</span> {moment(itemP.date).format("MMM D, YYYY")}
                                        </p>
                                    </li>}
                                    {itemP.payment_type === "online-payment" && <li>
                                        <p className={`${styles.AlertText}`}>
                                            <span>Payment method</span>
                                            {itemP?.payment_details?.payment_method === "card" && "Credit Card"}
                                            {itemP?.payment_details?.payment_method === "us_bank_account" && "ACH Bank Transfer"}                                            
                                        </p>
                                    </li>}
                                    {itemP.payment_type === "online-payment" && <li>
                                        <p className={`${styles.AlertText}`}>
                                            <span>To account</span> {itemP?.payment_details?.account}
                                        </p>
                                    </li>}
                                    {itemP?.payment_details?.status === "Failed" && <li>
                                        <p className={`${styles.AlertText}`}>
                                            <span>Failure Message</span> {itemP?.payment_details?.failure_message}
                                        </p>
                                    </li>}
                                </ul>
                            </div>
                        </AccordionDetails>
                    </Accordion >) : null;
                })}

                {paymentList.length > 0 && (<div className="Pagination TablePaginationNew">
                    <TablePagination
                        count={paymentList.length}
                        color="primary"
                        page={page}
                        rowsPerPage={perPage}
                        onPageChange={(e, v) => setPage(v)}
                        rowsPerPageOptions={[
                            { label: '5', value: 5 },
                            { label: '10', value: 10 },
                            { label: '15', value: 15 },
                            { label: 'All', value: -1 },
                        ]}
                        onRowsPerPageChange={(e) => {
                            setPage(0);
                            setPerPage(e.target.value);
                        }}
                        labelRowsPerPage={'Payments per Page:'}
                    />
                </div>)}

                </div >
            </div>
        )}

        {paymentList.length === 0 && (<div className={`${styles.NotesRow}`}>
            <div className={`${styles.NoDataMain}`}>
                <div>
                    <img src="/static/img/no-content.png" alt="" className={`${styles.NoDataIMG}`} />
                    <p className={`${styles.NoDataText}`}>No Payment Found</p>
                </div>
            </div>
        </div>)}

        <Dialog
            fullWidth
            maxWidth="xs"
            open={addBillModalShow}
            onClose={(e) => setAddBillModalShow(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp MobileForm"
        >
            <DialogTitle id="alert-dialog-title">
                <button className={`${styles.BackAddBU}`} onClick={(e) => setAddBillModalShow(false)}><ArrowLeft /></button>
                {isUpdate ? 'Update Bill' : 'Add Bill'}
                <button onClick={(e) => setAddBillModalShow(false)}><X /></button>
            </DialogTitle>
            <DialogContent className={`${styles.RequestBodyPop}`}>
                <AddBill rent_roll_id={tenantData?.last_rent_roll_id} setModalShow={setAddBillModalShow.bind(this)} setLoading={setLoading.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} addData={addData.bind(this)} updateData={updateData.bind(this)} isUpdate={isUpdate} editId={editId} />
            </DialogContent>
        </Dialog>

        <Dialog
            fullWidth
            maxWidth="xs"
            open={addCreditModalShow}
            onClose={(e) => setAddCreditModalShow(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp MobileForm"
        >
            <DialogTitle id="alert-dialog-title">
                <button className={`${styles.BackAddBU}`} onClick={(e) => setAddCreditModalShow(false)}><ArrowLeft /></button>
                {isUpdate ? 'Update Credit' : 'Add Credit'}
                <button onClick={(e) => setAddCreditModalShow(false)}><X /></button>
            </DialogTitle>
            <DialogContent className={`${styles.RequestBodyPop}`}>
                <AddCredit rent_roll_id={tenantData?.last_rent_roll_id} setModalShow={setAddCreditModalShow.bind(this)} setLoading={setLoading.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} addData={addData.bind(this)} updateData={updateData.bind(this)} isUpdate={isUpdate} editId={editId} />
            </DialogContent>
        </Dialog>

        <Dialog
            fullWidth
            maxWidth="xs"
            open={addPaymentModalShow}
            onClose={(e) => setAddPaymentModalShow(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp MobileForm"
        >
            <DialogTitle id="alert-dialog-title">
                <button className={`${styles.BackAddBU}`} onClick={(e) => setAddPaymentModalShow(false)}><ArrowLeft /></button>
                {isUpdate ? 'Update Payment' : 'Record Payment'}
                <button onClick={(e) => setAddPaymentModalShow(false)}><X /></button>
            </DialogTitle>
            <DialogContent className={`${styles.RequestBodyPop}`}>
                <RecordPayment rent_roll_id={tenantData?.last_rent_roll_id} setModalShow={setAddPaymentModalShow.bind(this)} setLoading={setLoading.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} addData={addData.bind(this)} updateData={updateData.bind(this)} isUpdate={isUpdate} editId={editId} />
            </DialogContent>
        </Dialog>
    </>
}

export default PaymentsTab;