import { FormControlLabel, Slider, Switch } from "@mui/material";
import styles from "../../smartdevicedetails.module.css";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SmartDeviceDetailsService from "../../smartdevicedetails.service";
import { ChevronDown } from "react-feather";

const Thermostatcontroller = ({ smartDeviceData }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [currentTemp, setcurrentTemp] = useState("");

    const thermostatLabels = {
        41: "41",
        54: "54",
        67: "68",
        80: "81",
        95: "95",
    }

    const thermostatMinMax = {
        min: 41,
        max: 95,
    }

    useEffect(() => {
        if (smartDeviceData && smartDeviceData?.status?.length) {
            const deviceInfoArray = smartDeviceData?.status;
            const currentTemperature = deviceInfoArray.find(item => item.code === 'temp_current_f');
            if (currentTemperature) {
                setcurrentTemp(currentTemperature.value);
            }
        }

    }, [smartDeviceData]);

    const valuetext = (value) => {
        return `${value}°C`;
    }

    const onStatusChange = async () => {
        try {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            smartDeviceData.status[0].value = !smartDeviceData.status[0].value;

            const payload = smartDeviceData.status[0];
            const headers = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            await SmartDeviceDetailsService.onStatusChange(smartDeviceData.id, payload, headers);
        } catch (error) {
            console.log(error);
        }
    }

    const sliderHandler = (event, value) => {
        smartDeviceData.status[4].value = value;
    }

    const onSliderChange = async (event, value) => {
        try {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            const payload = smartDeviceData.status[4];
            const headers = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            await SmartDeviceDetailsService.onStatusChange(smartDeviceData.id, payload, headers);
        } catch (error) {
            console.log(error);
        }
    }

    return (<div className={`${styles.cardUnderS}`}>
        {!smartDeviceData?.online && (<div className={`${styles.OfflineSection}`}>
            <img src="/static/img/offline.svg" width="60px" height="60px" alt="" />
            <p>Device Is Offline</p>
        </div>)}

        {/* <p className={`${styles.ControllTitle}`} onClick={OpenController}>Control Panel<ChevronDown/></p> */}
        <div className={`${styles.WidgetIndi}`}>
            <p className={`${styles.WidgetT}`}>Switch</p>
            <div className={`${styles.OnOffSwitchSmartDE} OnOffSwitchSmartDE`}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={smartDeviceData.status[0].value}
                            onChange={onStatusChange}
                            name="checkedA"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                    }
                />
            </div>
        </div>
        <div className={`${styles.WidgetIndi}`}>
            <div className={smartDeviceData?.online ? `` : `${styles.Disable}`}>
                <p className={`${styles.WidgetT}`}>Set Temp</p>
                <p className={`${styles.WidgetTB}`}>{smartDeviceData?.status[4]?.value}°<span>F</span></p>
                <p className={`${styles.WidgetT} ${styles.MarTop30}`}>Current Temp</p>
                <p className={`${styles.WidgetTB}`}>{currentTemp}°<span>F</span></p>
            </div>
            <div className={smartDeviceData?.online ? `DimmerWidget Thermostat` : `DimmerWidget Thermostat Disable ${styles.Disable}`}>
                <Slider
                    orientation="vertical"
                    getAriaValueText={valuetext}
                    value={smartDeviceData?.status[4].value}
                    aria-labelledby="vertical-slider"
                    min={thermostatMinMax.min}
                    max={thermostatMinMax.max}
                    onChange={sliderHandler}
                    onChangeCommitted={onSliderChange}
                    labels={thermostatLabels}
                />
                <div className={`${styles.RangeLabel} RangeLabel`}>
                    <p className={`${styles.RangeLabelText} ${styles.Text100} Text100`}>100%</p>
                    <p className={`${styles.RangeLabelText} ${styles.Text75} Text75`}>75%</p>
                    <p className={`${styles.RangeLabelText} ${styles.Text50} Text50`}>50%</p>
                    <p className={`${styles.RangeLabelText} ${styles.Text25} Text25`}>25%</p>
                    <p className={`${styles.RangeLabelText} ${styles.Text0} Text0`}>0%</p>
                </div>
                <div className={`${styles.RangeIMG} RangeIMG`}>
                    <img src="/static/img/Range-markT.svg" className={`${styles.RangeMark}`} alt="" />
                    <img src="/static/img/Range-mark-D.svg" className={`${styles.RangeMarkD}`} alt="" />
                </div>
            </div>
        </div>
    </div>);
}

export default Thermostatcontroller;