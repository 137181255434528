import { useEffect, useState } from "react";
import styles from "../dashboard.module.css";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { DashCardLoader } from "../../../../components/LoaderC";

const RentPayments = ({ selectedProperty, filterDateRange }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [paymentList, setpaymentList] = useState([]);
    const [contentLoading, setContentLoading] = useState(true);

    useEffect(() => {
        const fetchData = async (pId) => {
            setContentLoading(true);
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            let params = {};

            if (filterDateRange?.start && filterDateRange?.end) {
                params = { start: filterDateRange?.start, end: filterDateRange?.end };
            }

            if (pId > 0) {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-payment-list/" + pId, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: params
                }).then((response) => {
                    setContentLoading(false);
                    setpaymentList(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                    setpaymentList([]);
                });
            } else {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-payment-list", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: params
                }).then((response) => {
                    setContentLoading(false);
                    setpaymentList(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                    setpaymentList([]);
                });
            }
        }

        if (selectedProperty > 0) {
            fetchData(selectedProperty);
        } else {
            fetchData(0);
        }
    }, [selectedProperty, getAccessTokenSilently, filterDateRange]);

    return (<div className={`${styles.CardDivTwo} ${styles.CardRent} ${styles.CardHalf}`}>
        <div className={`${styles.IfnoCard}`}>
            <div className={`${styles.InsideCard}`}>


                {contentLoading && (<div className={`${styles.LoaderDash2}`}>
                    <DashCardLoader />
                </div>)}

                {!contentLoading && (<>
                    <div className={`${styles.HeadFlex}`}>
                        <h6 className={`${styles.InsideCardTitle2}`}>Rent Payments</h6>
                        <Link to="/payments" className={`${styles.ViewAllBU}`}>View All</Link>
                    </div>
                    <div className={`${styles.ScrollCard}`}>
                        {paymentList?.map((item) => {
                            return (<Link to={"/payment/rent-roll/" + item?.rent_roll_id + "/details"} className={`${styles.CaseCard}`} key={item?.id}>
                                <div className={`${styles.CaseCardTitle} ${styles.RentRoll}`}>
                                    <div className={`${styles.RentCardNSec}`}>
                                        <p className={`${styles.TitleText}`}>
                                            <Tooltip title={item?.tenant_name} placement="bottom-start">
                                                <span className={`${styles.NoWrapTwo}`}>{item?.tenant_name}</span>
                                            </Tooltip>
                                        </p>
                                        <p className={`${styles.TitleName}`}>{item?.apt_name}</p>
                                    </div>
                                    <div className={`${styles.RentCardPSec}`}>
                                        <Tooltip title={item?.payment_text} placement="bottom-start">
                                            <p className={item?.payment_status === 0 ? `${styles.PaymentText} ${styles.Yellow}` : item?.payment_status === 2 ? `${styles.PaymentText} ${styles.Red}` : `${styles.PaymentText} ${styles.Green}`}>{item?.payment_text}</p>
                                        </Tooltip>
                                        <p className={`${styles.TitleName}`}>Balance: ${item?.formatted_balance}</p>
                                    </div>
                                    <div className={`${styles.RentCardTSec}`}>
                                        <p className={`${styles.DateText}`}>{item?.submitted_date}</p>
                                    </div>
                                </div>
                            </Link>);
                        })}

                        {paymentList?.length === 0 && (<div className={`${styles.NoDataSec}`}>
                            <div className={`${styles.NoDataIMG}`}><img src="/static/img/no-payment.png" alt="" /></div>
                            <p className={`${styles.NoDataText}`}>Let's get started!</p>
                            <p className={`${styles.NoDataTextSub}`}>Payments History is Empty.</p>
                        </div>)}
                    </div>
                </>)}
            </div>
        </div>
    </div>);

}

export default RentPayments;