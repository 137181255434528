import { useEffect, useState } from "react";
import styles from "../dashboard.module.css";
import { Tooltip } from "@mui/material";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { DashCardLoader } from "../../../../components/LoaderC";

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: 13,
    },
}));

const BattryStatuses = ({ selectedProperty }) => {
    const { getAccessTokenSilently } = useAuth0();
    const redirect = useHistory();
    const [noData, setNoData] = useState(true);
    const [statusList, setStatusList] = useState([]);
    const [contentLoading, setContentLoading] = useState(true);
    const [lockBatteryStatus, setLockBatteryStatus] = useState([]);

    useEffect(() => {
        const fetchData = async (pId) => {
            setContentLoading(true);
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            if (pId > 0) {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-device-battery-status/" + pId, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    setContentLoading(false);
                    setLockBatteryStatus(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                    setLockBatteryStatus([]);
                });
            } else {
                setLockBatteryStatus([]);
                setContentLoading(false);
            }
        }

        if (selectedProperty > 0) {
            fetchData(selectedProperty);
        } else {
            fetchData(0);
        }
    }, [selectedProperty, getAccessTokenSilently]);

    useEffect(() => {
        if (lockBatteryStatus?.length > 0) {
            let vehicleReduce = (lockBatteryStatus, key) =>
                Object.values(lockBatteryStatus.reduce((acc, status) => {
                    let value = status[key];
                    acc[value] = acc[value] || { text: '', statuses: [] };
                    acc[value].text = 'Floor ' + value;
                    acc[value].statuses.push(status);

                    return acc;
                }, {}));

            setStatusList(vehicleReduce(lockBatteryStatus, 'floor'));

            let isNoData = true;
            lockBatteryStatus?.map(i => {
                if (i?.electric_quantity) {
                    isNoData = false;
                }

                return null;
            })
            setNoData(isNoData);
        } else {
            setNoData(true);
            setStatusList([]);
        }


    }, [lockBatteryStatus]);

    return (<div className={`${styles.IconCardDiv}`}>
        <div className={`${styles.IconCardFullDiv}`}>
            <div className={`${styles.IfnoCardTwo}`}>
                <div className={`${styles.InsideCard}`}>


                    {contentLoading && (<div className={`${styles.LoaderDash2}`}>
                        <DashCardLoader />
                    </div>)}

                    {!contentLoading && (<>
                        <div className={`${styles.BatteryHead}`}>
                            <h6 className={`${styles.BatteryCardTitle}`}>Battery Status</h6>
                        </div>
                        {!noData && (<>
                            {statusList?.map((item, index) => {
                                return (<>

                                    <div className={`${styles.BatteryChartRow}`} key={index}>
                                        <p className={`${styles.BetteryChartTitle}`}>{item?.text}</p>
                                        <div className={`${styles.BetteryChartSec}`}>
                                            {item?.statuses?.map((subItem, subIndex) => {
                                                return (<div className={(subItem?.electric_quantity ? ((subItem?.electric_quantity > 20) ? `${styles.BetteryCount} ${styles.Green}` : ((subItem?.electric_quantity > 10) ? `${styles.BetteryCount} ${styles.Orange}` : `${styles.BetteryCount} ${styles.Red}`)) : `${styles.BetteryCount}`)}>
                                                    {subItem?.electric_quantity ? <BootstrapTooltip title={<>{subItem?.apt_no}<br />{"Battery " + subItem?.electric_quantity + "% remaining"}</>}>
                                                        <div className={`${styles.BetteryCountUnder}`} style={{ cursor: 'pointer' }} onClick={(e) => {
                                                            localStorage.setItem("propertyFilterData", selectedProperty);
                                                            localStorage.setItem("aptFilterData", subItem?.apt_id);
                                                            localStorage.setItem("productFilterData", 'Propertydek-Door Lock');
                                                            redirect.push('/smart-devices/list');
                                                        }}>
                                                            <p>{subItem?.electric_quantity && subItem?.electric_quantity + '%'}</p>
                                                        </div>
                                                    </BootstrapTooltip> : <BootstrapTooltip title={<>{subItem?.apt_no}</>}>
                                                        <div className={`${styles.BetteryCountUnder}`}>
                                                            <p>{subItem?.electric_quantity && subItem?.electric_quantity + '%'}</p>
                                                        </div>
                                                    </BootstrapTooltip>}

                                                </div>)
                                            })}
                                        </div>
                                    </div>
                                </>);
                            })}
                        </>)
                        }
                        {noData && (<div className={`${styles.NoDataSec}`}>
                            <div className={`${styles.NoDataIMG}`}><img src="/static/img/no-battery.png" alt="" /></div>
                            <p className={`${styles.NoDataText}`}>Oops!</p>
                            <p className={`${styles.NoDataTextSub}`}>Battery Not Detected.</p>
                        </div>)}
                    </>)}
                </div>

            </div>
        </div>
    </div>);
}

export default BattryStatuses;