import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { ArrowLeft, Calendar, Home, Plus, X } from "react-feather";
import styles from "./dashboard.module.css";
import Helmet from "react-helmet";
import Footer from "../../../components/Footer";
import { Typography as MuiTypography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Slide, Snackbar } from "@mui/material";
import StepOneForm from "../../prospect/Components/AddProspect/StepOneForm";
import StepTwoForm from "../../prospect/Components/AddProspect/StepTwoForm";
import StepThreeForm from "../../prospect/Components/AddProspect/StepThreeForm";
import { toast } from "react-smart-toaster";
import dayjs from "dayjs";
import AddForm from "../../tenants/components/AddForm";
import Loader from "../../../components/Loader";
import SmartDeviceAlerts from "./Components/SmartDeviceAlerts";
import Prospects from "./Components/Prospects";
import AddDeviceForm from "../../smartdevices/AddComponent/AddDeviceFormNew";
import AddPropertyForm from "../../properties/components/AddPropertyForm";
import { AddLogCallAPI } from "../../../components/AddLogs";
import AddMaintenanceForm from "../../maintenancerequest/components/AddMaintenanceForm";
import Ruquests from "./Components/Requests";
import RentPayments from "./Components/RentPayments";
import MonthlyRentRollData from "./Components/MonthlyRentRollData";
import MonthlyCasesData from "./Components/MonthlyCasesData";
import LeaseData from "./Components/LeaseData";
import MonthCollection from "./Components/MonthCollection";
import MonthCollection2 from "./Components/MonthlyCollection2";
import BattryStatuses from "./Components/BattryStatuses";
import SmartDeviceStatus3D from "./Components/SmartDeviceStatus3D";
import ApartmentDataHeatMap from "./Components/ApartmentDataHeatMap";
import { DateRangePicker } from "rsuite";
import OpenRequestsCard from "./Components/OpenRequestsCard";
import ReceivedMessages from "./Components/ReceivedMessages";
import DeviceStatutes from "./Components/DeviceStatutes";
import OccupiedUnits from "./Components/OccupiedUnits";
import CompletedTours from "./Components/CompletedTours";
import AppliedProspects from "./Components/AppliedProspects";

const Typography = styled(MuiTypography)(spacing);

function Default() {
  const { getAccessTokenSilently, user } = useAuth0();
  const [propertyList, setPropertyList] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [addProperty, setAddProperty] = useState(false);
  const [addProspect, setAddProspect] = useState();
  const [addFormStep, setAddFormStep] = useState(1);
  const [stepOneData, setStepOneData] = useState(null);
  const [stepTwoData, setStepTwoData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addTenantPop, setAddTenantPop] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [addDevicePop, setAddDevicePop] = useState(false);
  const [addRequestPop, setAddRequestPop] = useState(false);
  const [filterDateRange, setFilterDateRange] = useState({ start: null, end: null });

  useEffect(() => {
    async function fetchData() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      await axios.get(process.env.REACT_APP_USER_API_URL + "v1/get-all-properties", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        let propertyListTemp = response.data.data;
        propertyListTemp = [{ value: 0, label: "All" }].concat(propertyListTemp);
        setPropertyList(propertyListTemp);
        setSelectedProperty(0);
      }).catch((error) => {
        setPropertyList([]);
      });
    }
    fetchData();
  }, [getAccessTokenSilently, user]);

  const onSubmitOne = (data) => {
    setAddFormStep(2);
    setStepOneData(data);
  };

  const onSubmitTwo = (data) => {
    let cData = {};
    if (typeof data.credit_score !== "undefined") {
      cData = { ...cData, credit_score: data.credit_score };
    }
    if (typeof data.have_pets !== "undefined") {
      cData = { ...cData, have_pets: data.have_pets };
    }
    if (typeof data.income_per_year !== "undefined") {
      cData = { ...cData, income_per_year: data.income_per_year };
    }
    if (typeof data.bedrooms !== "undefined") {
      cData = { ...cData, bedrooms: data.bedrooms };
    }
    if (typeof data.move_in_date !== "undefined") {
      cData = { ...cData, move_in_date: dayjs(data?.move_in_date).format("YYYY-MM-DD HH:mm:ss"), };
    }
    if (typeof data.bathrooms !== "undefined") {
      cData = { ...cData, bathrooms: data.bathrooms };
    }
    if (typeof data.do_you_smoke !== "undefined") {
      cData = { ...cData, do_you_smoke: data.do_you_smoke };
    }
    if (typeof data.is_tour !== "undefined") {
      cData = { ...cData, is_tour: data.is_tour };
    }

    setAddFormStep(3);
    setStepTwoData(cData);
  };

  const onAddProspectSubmit = async (data) => {
    let postData = {};
    postData = { ...postData, ...stepOneData, ...stepTwoData };
    if (addFormStep === 2) {
      if (typeof data.credit_score !== "undefined") {
        postData = { ...postData, credit_score: data.credit_score };
      }
      if (typeof data.have_pets !== "undefined") {
        postData = { ...postData, have_pets: data.have_pets };
      }
      if (typeof data.income_per_year !== "undefined") {
        postData = { ...postData, income_per_year: data.income_per_year };
      }
      if (typeof data.bedrooms !== "undefined") {
        postData = { ...postData, bedrooms: data.bedrooms };
      }
      if (typeof data.move_in_date !== "undefined") {
        postData = { ...postData, move_in_date: dayjs(data?.move_in_date).format("YYYY-MM-DD HH:mm:ss"), };
      }
      if (typeof data.bathrooms !== "undefined") {
        postData = { ...postData, bathrooms: data.bathrooms };
      }
      if (typeof data.do_you_smoke !== "undefined") {
        postData = { ...postData, do_you_smoke: data.do_you_smoke };
      }
    }

    if (typeof data.tour_date_time !== "undefined") {
      postData = { ...postData, tour_date_time: data.tour_date_time };
    }

    setAddProspect(false);
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    let is_tour = false;
    if (typeof postData.is_tour !== "undefined") {
      is_tour = postData.is_tour;
    }

    await axios.post(process.env.REACT_APP_APPLICANT_API_URL + "v1/add-prospect", postData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setLoading(false);

      setSnackbarMsg(response.data.message);
      setSnackbarOpen(true);

      let logData = {
        title: "A new prospect is added",
        description: [
          "Name: " + response?.data?.data?.full_name,
          "Phone: " + response?.data?.data?.phone,
          "Property: " + response?.data?.data?.property_name,
          "Apartment: " + response?.data?.data?.apt_no,
        ],
      };
      AddLogCallAPI(logData, token);

      if (is_tour) {
        let logData = {
          title: "A new prospect tour is added",
          description: [
            "Name: " + response?.data?.data?.full_name,
            "Phone: " + response?.data?.data?.phone,
            "Property: " + response?.data?.data?.property_name,
            "Date/Time: " +
            moment(data.tour_date_time).format("MM/DD/YYYY hh:mm a"),
          ],
        };
        AddLogCallAPI(logData, token);
      }
    }).catch((error) => {
      setLoading(false);
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
    });
  };

  const addTenant = (data) => {
    setAddTenantPop(false);
    setSnackbarMsg("Tenant(s) have been added successfully.");
    setSnackbarOpen(true);
  };



  const onDeviceAdd = (data) => {
    console.log(data);
  };

  const onRequestAdd = (data) => {
    console.log(data);
  }

  const onAddProperty = (data) => {
    setSnackbarMsg(data.message);
    setSnackbarOpen(true);
  };

  return (<React.Fragment>
    {loading && <Loader />}

    <Helmet title="Dashboard" />

    <div className={`${styles.pageTitleWrap}`}>
      <Typography display="inline" className={`${styles.pageTitle}`}>Dashboard</Typography>

      <nav aria-label="breadcrumb">
        <ol className={`${styles.breadcrumb}`}>
          <li className={`${styles.breadcrumbItem}`}>
            <span><Home /></span>Dashboard
          </li>
        </ol>
      </nav>
    </div>

    <div className={`${styles.mainCard}`}>
      <div className={`${styles.Row}`}>
        <div className={`${styles.DahhboardHead}`}>
          <div className={`${styles.SwitchDiv} ${styles.Select}`}>
            <div className={`${styles.FormGroupSelect} FormGroup`}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Property*</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Select Property" value={selectedProperty} onChange={(e) => setSelectedProperty(e?.target?.value)}>
                  {propertyList?.map(item => {
                    return (<MenuItem key={item?.value} value={item?.value}> {item?.label} </MenuItem>);
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className={`${styles.FilterCard}`}>
            <div className={`${styles.DashFilterSec} DashFilterSec Filter`}>
              <DateRangePicker
                format={"M/d/yy"}
                placeholder={"Filter by date"}
                placement={"bottomEnd"}
                caretAs={Calendar}
                showOneCalendar
                onChange={(e) => {
                  if (e) {
                    setFilterDateRange({ start: moment(e[0]).format("YYYY-MM-DD"), end: moment(e[1]).format("YYYY-MM-DD") });
                  } else {
                    setFilterDateRange({ start: null, end: null });
                  }
                }}
              />
            </div>
          </div>

          <Dropdown>
            <Dropdown.Toggle className={`${styles.ActionsBU} ActionsBUT`} id="dropdown-basic">
              <Plus className={`${styles.Add}`} /> Add
            </Dropdown.Toggle>

            <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
              <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => setAddProperty(true)}>
                <img src="/static/img/home-dash.svg" width="20px" height="20px" alt="" />
                Property
              </Dropdown.Item>
              <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => { setAddProspect(true); setAddFormStep(1); }}>
                <img src="/static/img/prospect-dash.svg" width="20px" height="20px" alt="" />
                Prospect
              </Dropdown.Item>
              <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => setAddTenantPop(true)}>
                <img src="/static/img/tenant-dash.svg" width="20px" height="20px" alt="" />
                Tenant
              </Dropdown.Item>
              <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => setAddRequestPop(true)}>
                <img src="/static/img/request-dash.svg" width="20px" height="20px" alt="" />
                Request
              </Dropdown.Item>
              <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => setAddDevicePop(true)}>
                <img src="/static/img/device-dash.svg" width="20px" height="20px" alt="" />
                Smart Device
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <AppliedProspects selectedProperty={selectedProperty} />

        <CompletedTours selectedProperty={selectedProperty} />

        <OccupiedUnits selectedProperty={selectedProperty} />

        <DeviceStatutes selectedProperty={selectedProperty} />

        <ReceivedMessages selectedProperty={selectedProperty} />

        <OpenRequestsCard selectedProperty={selectedProperty} />

        <div className={`${styles.IconCardDiv}`}>
          <div className={`${styles.IconCardFullDiv}`}>
            <div className={`${styles.IfnoCardTwo}`}>

              <div className={`${styles.RentCollectBigSec}`}>
                <MonthCollection selectedProperty={selectedProperty} filterDateRange={filterDateRange} />
                <LeaseData selectedProperty={selectedProperty} />
              </div>
              <div className={`${styles.RentCollectSmallSec}`}>
                <MonthCollection2 selectedProperty={selectedProperty} filterDateRange={filterDateRange} />
              </div>

            </div>
          </div>
        </div>

        <BattryStatuses selectedProperty={selectedProperty} />

        <SmartDeviceAlerts selectedProperty={selectedProperty} filterDateRange={filterDateRange} />

        <Ruquests selectedProperty={selectedProperty} filterDateRange={filterDateRange} />

        <Prospects selectedProperty={selectedProperty} filterDateRange={filterDateRange} />

        <RentPayments selectedProperty={selectedProperty} filterDateRange={filterDateRange} />

        <MonthlyCasesData selectedProperty={selectedProperty} />

        <MonthlyRentRollData selectedProperty={selectedProperty} />

        <SmartDeviceStatus3D />

        <ApartmentDataHeatMap />

      </div>
    </div>

    <Dialog
      open={addProperty}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setAddProperty(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
    >

      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => setAddProperty(false)}><ArrowLeft /></button>
        Add Property
        <button onClick={(e) => setAddProperty(false)}>
          <X />
        </button>
      </DialogTitle>
      <DialogContent>
        <AddPropertyForm closeForm={setAddProperty.bind(this)} onSubmitLocal={onAddProperty.bind(this)} />
      </DialogContent>
    </Dialog>

    <Dialog
      open={addProspect}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setAddProspect(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp CustomWidthPopUs"
    >
      {addFormStep === 1 && (<StepOneForm setOpenAddProspectModal={setAddProspect.bind(this)} onSave={onSubmitOne.bind(this)} selectedProperty={0} />)}

      {addFormStep === 2 && (<StepTwoForm setOpenAddProspectModal={setAddProspect.bind(this)} onSave={onSubmitTwo.bind(this)} onSubmit={onAddProspectSubmit.bind(this)} isTourOption={true} />)}

      {addFormStep === 3 && (<StepThreeForm setOpenAddProspectModal={setAddProspect.bind(this)} onSubmit={onAddProspectSubmit.bind(this)} prospect_name={stepOneData?.prospect_name} property_id={stepOneData?.property_id} />)}
    </Dialog>

    <Dialog
      open={addTenantPop}
      fullWidth
      maxWidth="lg"
      onClose={(e) => setAddTenantPop(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp AddTenantDash"
    >
      <AddForm addTenant={addTenant.bind(this)} closeAddForm={(e) => setAddTenantPop(false)} />
      <button onClick={(e) => setAddTenantPop(false)} className={`${styles.CloseAddTenantDash}`}>
        <X />
      </button>
    </Dialog>

    <Dialog
      open={addRequestPop}
      fullWidth
      maxWidth="lg"
      onClose={(e) => setAddRequestPop(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp AddTenantDash"
    >
      <AddMaintenanceForm closeForm={setAddRequestPop.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} onSubmitLocal={onRequestAdd.bind(this)} />
      <button onClick={(e) => setAddRequestPop(false)} className={`${styles.CloseAddTenantDash}`}>
        <X />
      </button>
    </Dialog>

    <Dialog
      open={addDevicePop}
      fullWidth
      maxWidth="lg"
      onClose={(e) => setAddDevicePop(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp AddTenantDash"
    >
      <AddDeviceForm setShowAddFormNew={setAddDevicePop.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} onSubmitLocal={onDeviceAdd.bind(this)} />
      <button onClick={(e) => setAddDevicePop(false)} className={`${styles.CloseAddTenantDash}`}>
        <X />
      </button>
    </Dialog>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />

    <Footer />
  </React.Fragment>);
}

export default Default;