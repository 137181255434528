import { useEffect, useState } from "react";
import styles from "../dashboard.module.css"; import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { DashCardLoader } from "../../../../components/LoaderC";
import { ArrowDown, ArrowUp } from "react-feather";

const OpenRequestsCard = ({ selectedProperty }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [requestData, setRequestData] = useState(null);
    const [contentLoading, setContentLoading] = useState(true);

    useEffect(() => {
        const fetchData = async (pId) => {
            setContentLoading(true);
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            if (pId > 0) {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-open-request-data/" + pId, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    setContentLoading(false);
                    setRequestData(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                    setRequestData(null);
                });
            } else {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-open-request-data", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    setContentLoading(false);
                    setRequestData(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                    setRequestData(null);
                });
            }
        }

        if (selectedProperty > 0) {
            fetchData(selectedProperty);
        } else {
            fetchData(0);
        }
    }, [selectedProperty, getAccessTokenSilently]);

    return (<div className={`${styles.RequestCardDiv}`}>
        {contentLoading && (<div className={`${styles.LoaderDash}`}>
            <DashCardLoader />
        </div>)}

        {!contentLoading && (
            <div className={`${styles.RequestCardDivUnder}`}>
                <div className={`${styles.RequestCardHead}`}>
                    <div>
                        <p className={`${styles.ScoeCardTitle}`}>Open Request</p>
                        <p className={`${styles.RequestCradNTitle}`}>{requestData ? requestData?.total : 0}</p>
                    </div>
                    {(requestData && requestData?.last_30_days) && <p className={`${styles.RequestInfoText}`}>+{requestData?.last_30_days} of open request in last 30 days</p>}
                </div>
                <div className={`${styles.RequestCardBody}`}>
                    <div className={`${styles.RequestCardList} ${styles.Red}`}>
                        <div className={`${styles.ReqCardListTitleSec}`}>
                            <ArrowUp />
                            <p>Overdue</p>
                        </div>
                        <p className={`${styles.CardListScore}`}>{requestData ? requestData?.overdue : 0}</p>
                    </div>
                    <div className={`${styles.RequestCardList} ${styles.High}`}>
                        <div className={`${styles.ReqCardListTitleSec}`}>
                            <ArrowUp />
                            <p>High</p>
                        </div>
                        <p className={`${styles.CardListScore}`}>{requestData ? requestData?.hard : 0}</p>
                    </div>
                    <div className={`${styles.RequestCardList} ${styles.Medium}`}>
                        <div className={`${styles.ReqCardListTitleSec}`}>
                            <ArrowUp />
                            <p>Medium</p>
                        </div>
                        <p className={`${styles.CardListScore}`}>{requestData ? requestData?.medium : 0}</p>
                    </div>
                    <div className={`${styles.RequestCardList} ${styles.Green}`}>
                        <div className={`${styles.ReqCardListTitleSec}`}>
                            <ArrowDown />
                            <p>Low</p>
                        </div>
                        <p className={`${styles.CardListScore}`}>{requestData ? requestData?.low : 0}</p>
                    </div>
                </div>
            </div>
        )}
    </div>);
}

export default OpenRequestsCard;