import React, { useEffect, useState } from "react";
import styles from "./maintenancerequestdetails.module.css";
import { Link, useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import { Home, X, ArrowLeft, Archive, Trash2, Edit, MoreVertical, ChevronDown } from "react-feather";
import { Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Slide, IconButton } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import Footer from "../../components/Footer";
import axios from "axios";
import { toast } from "react-smart-toaster";
import Loader from "../../components/Loader";
import { TableLoader } from "../../components/LoaderC";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { AddLogCallAPI } from "../../components/AddLogs";
import { useAuth0 } from "@auth0/auth0-react";
import SimpleTabs from "./components/SimpleTabs";
import dayjs from "dayjs";
import EditMaintenanceForm from "./components/EditMaintenanceForm";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const OpenedToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref} className={`${styles.ReferStatusBU} ${styles.Open}`} onClick={(e) => { e.preventDefault(); onClick(e); }}>
    Open <ChevronDown className={`${styles.DownArrowStatus}`} />
  </button>
));

const ResolvedToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref} className={`${styles.ReferStatusBU} ${styles.Resolved}`} onClick={(e) => { e.preventDefault(); onClick(e); }}>
    Resolved <ChevronDown className={`${styles.DownArrowStatus}`} />
  </button>
));

const InprogressToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref} className={`${styles.ReferStatusBU} ${styles.InProgress}`} onClick={(e) => { e.preventDefault(); onClick(e); }}>
    In-Progress <ChevronDown className={`${styles.DownArrowStatus}`} />
  </button>
));

const CancelledToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button ref={ref} className={`${styles.ReferStatusBU} ${styles.Cancelled}`} onClick={(e) => { e.preventDefault(); onClick(e); }}>
    Cancelled <ChevronDown className={`${styles.DownArrowStatus}`} />
  </button>
));

const MaintenanceRequestDetails = ({ history, match }) => {
  const { id } = match.params;
  const redirect = useHistory();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [requestData, setRequestData] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [delModalOpen, setDelModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);

  useEffect(() => {
    async function fetchRequestDetails() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      setContentLoading(true);
      await axios.get(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setContentLoading(false);
        setRequestData(response.data.data);
      }).catch((error) => {
        setContentLoading(false);
      });
    }

    fetchRequestDetails();
  }, [getAccessTokenSilently, id]);

  const archiveConfirm = async () => {
    setArchiveModalOpen(false);
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    let is_archived = 1 - parseInt(requestData.is_archived);
    axios.put(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + requestData.id, { is_archived: is_archived }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setLoading(false);

      setSnackbarOpen(true);
      setSnackbarMsg(response.data.message);

      setRequestData((prev) => {
        return { ...prev, is_archived: is_archived };
      });
      let logData = {
        title: is_archived ? "Maintenance request is archived" : "Maintenance request is unarchived",
        description: [
          "Name: " + response.data.data?.title,
          "Request number: " + response.data.data?.request_no,
          "Property: " + response.data.data?.property_name,
          "Apartment: " + response.data.data?.apt_name,
          "Tenant: " + response.data.data?.tenant_name,
        ],
      };
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  }

  const delConfirm = async () => {
    setDelModalOpen(false);
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    return axios.delete(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + requestData?.id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setLoading(false);

      setSnackbarOpen(true);
      setSnackbarMsg(response.data.message);

      let logData = {
        title: "Maintenance request is deleted",
        description: [
          "Name: " + response.data.data?.title,
          "Request number: " + response.data.data?.request_no,
          "Property: " + response.data.data?.property_name,
          "Apartment: " + response.data.data?.apt_name,
          "Tenant: " + response.data.data?.tenant_name,
        ],
      };
      AddLogCallAPI(logData, token);
      setTimeout(() => {
        redirect.push("/cases/maintenance-request");
      }, 2000);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  }

  const changeStatus = async (status) => {
    if (requestData.current_status !== status) {
      setLoading(true);

      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios.put(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + requestData?.id, { current_status: status }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setLoading(false);

        setSnackbarOpen(true);
        setSnackbarMsg(response.data.message);

        let respData = response.data.data;

        setRequestData(prev => {
          return { ...prev, current_status: respData.current_status, current_status_text: respData.current_status_text };
        });

        let statusText = "Open";
        if (respData.current_status === 1) {
          statusText = "In-Progress";
        }
        if (respData.current_status === 2) {
          statusText = "Resolved";
        }
        if (respData.current_status === 3) {
          statusText = "Cancelled";
        }
        let logData = {
          title: "Maintenance request status changed",
          description: [
            "Name: " + respData.title,
            "Request number: " + respData.request_no,
            "Property: " + respData.property_name,
            "Apartment: " + respData.apt_name,
            "Tenant: " + respData.tenant_name,
            "Status: " + statusText,
          ],
        };
        AddLogCallAPI(logData, token);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        this.setState({ ...this.state, loading: false });
      });
    }
  }

  return (<React.Fragment>

    {loading && <Loader />}


    {!contentLoading && <Helmet title={requestData?.title} />}


    <div className={`${styles.mainCard}`}>
      {contentLoading && (<div className={`${styles.LoaderCard}`}><div className="Loader TableLoader">
        <TableLoader />
      </div></div>)}
      {!contentLoading && (<div className={`${styles.SMProCusRow}`}>
        <div className={`${styles.BreadcrumbHeadSec}`}>
          <nav aria-label="breadcrumb">
            <ol className={`${styles.breadcrumb}`}>
              <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Cases</li>
              <li className={`${styles.breadcrumbItem}`}><Link to={"/cases/maintenance-request"}>Maintanence Request</Link></li>
              <li className={`${styles.breadcrumbItem} ${styles.active}`}>{requestData?.title}</li>
            </ol>
          </nav>
          <div className={`${styles.PageTitleSec}`}>
            <div>
              <button onClick={() => redirect.goBack()} className={`${styles.BackArrow}`}><ArrowLeft /></button>
              <Typography display="inline" className={`${styles.pageTitle}`}>{requestData?.title}</Typography>
            </div>
          </div>
        </div>

        <div className={`${styles.SMProCusSmallDiv}`}>
          <div className={`${styles.CardSmall}`}>
            {requestData?.images?.length > 0 && <div className={`${styles.RequestCarouselSec} RequestCarouselSec`}>
              <Carousel autoPlay={true} showThumbs={false} showArrows={false} showStatus={false} showIndicators={requestData?.images?.length > 1} interval="3000">
                {requestData?.images?.slice(0, 5).map((item, index) => {
                  return <div key={index}>
                    <img src={item?.file_public_url} alt="" />
                  </div>
                })}
              </Carousel>
            </div>}
            <ul className={`${styles.CaseSummSideUL}`}>
              <li>
                <p className={`${styles.ULtitle}`}>Property</p>
                <p className={`${styles.ULData}`}>{requestData?.property_name}</p>
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Apartment No</p>
                <p className={`${styles.ULData}`}>{requestData?.apt_name}</p>
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Submitted By</p>
                {requestData?.submitted_by_tenant === 0 && <p className={`${styles.ULData}`}>{requestData?.submitted_by_name}</p>}
                {requestData?.submitted_by_tenant === 1 && <p className={`${styles.ULData}`}><Link to={"/people/tenants/details/" + requestData?.tenant_id}>{requestData?.submitted_by_name}</Link></p>}
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Tenant</p>
                <p className={`${styles.ULData}`}><Link to={"/people/tenants/details/" + requestData?.tenant_id}>{requestData?.tenant_name}</Link></p>
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Submitted Date</p>
                <p className={`${styles.ULData}`}>{dayjs(requestData?.created_at).format("L")}</p>
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Best time to contact tenant</p>
                <p className={`${styles.ULData}`}>{requestData?.contact_time_val} | {requestData?.contact_time_start} - {requestData?.contact_time_end}</p>
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Request Number</p>
                <p className={`${styles.ULData}`}>{requestData?.request_no}</p>
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Status</p>
                <p className={`${styles.ULData}`}>{requestData?.current_status_text}</p>
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Importance:</p>
                <p className={`${styles.ULData}`}>{requestData?.priority_text}</p>
              </li>
            </ul>

            <Dropdown className={`${styles.ProEditSec}`}>
              <Dropdown.Toggle className={`${styles.EditActionsBU} ProsDrBU`} id="dropdown-basic">
                <MoreVertical />
              </Dropdown.Toggle>

              <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => setEditModalOpen(true)}>
                  <Edit /> Edit
                </Dropdown.Item>
                <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => setArchiveModalOpen(true)}>
                  <Archive /> {requestData?.is_archived ? "Unarchive" : "Archive"}
                </Dropdown.Item>
                <Dropdown.Item className={`${styles.EditActionsBUListItem} ${styles.Delete}`} onClick={(e) => setDelModalOpen(true)}>
                  <Trash2 /> Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className={`${styles.SMReferDeBigDiv}`}>
          <div className={`${styles.Card}`}>
            <div className={`${styles.cardbody}`}>
              <div className={`${styles.ActionRefMain}`}>
                <div className={`${styles.TitleSummary}`}>
                  <p className={`${styles.Title}`}>{requestData?.title}</p>
                  {requestData?.is_archived === 0 && (<div className={`${styles.MainReqConDropBU}`}>
                    {(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("request:update")) > -1 && (<Dropdown>
                      {requestData.current_status === 0 && (<Dropdown.Toggle as={OpenedToggle} />)}
                      {requestData.current_status === 1 && (<Dropdown.Toggle as={InprogressToggle} />)}
                      {requestData.current_status === 2 && (<Dropdown.Toggle as={ResolvedToggle} />)}
                      {requestData.current_status === 3 && (<Dropdown.Toggle as={CancelledToggle} />)}

                      <Dropdown.Menu className="DropDownMenuBoxCus">
                        <Dropdown.Item className="DropDListWIcon" onClick={(e) => { e.stopPropagation(); changeStatus(0); }}>Open</Dropdown.Item>
                        <Dropdown.Item className="DropDListWIcon" onClick={(e) => { e.stopPropagation(); changeStatus(1); }}>In-Progress</Dropdown.Item>
                        <Dropdown.Item className="DropDListWIcon" onClick={(e) => { e.stopPropagation(); changeStatus(2); }}>Resolved</Dropdown.Item>
                        <Dropdown.Item className="DropDListWIcon" onClick={(e) => { e.stopPropagation(); changeStatus(3); }}>Cancelled</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>)}
                  </div>)}
                  {(requestData?.is_archived === 0 && requestData?.overdue) && (<span className={`${styles.TagDiv} ${styles.Red}`}>
                    {requestData?.overdue}
                  </span>)}

                  {requestData?.is_archived === 1 && (<span className={`${styles.ArchivedBadge}`}>Archived</span>)}
                </div>
              </div>
              <div className={`${styles.SMBigSideBody} TabMainClientProfile ClientDetails`}>
                <SimpleTabs
                  requestData={requestData}
                  setRequestData={setRequestData.bind(this)}
                  setLoading={setLoading.bind(this)}
                  appointments={requestData?.appointments}
                  labors={requestData?.labors}
                  materials={requestData?.materials}
                  contractors={requestData?.contractors}
                  vehicles={requestData?.vehicles}
                  notes={requestData?.notes}
                  histories={requestData?.histories}
                  feedbacks={requestData?.feedbacks}
                  setSnackbarOpen={setSnackbarOpen.bind(this)}
                  setSnackbarMsg={setSnackbarMsg.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>

      </div>)}
    </div>


    <Dialog
      open={editModalOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setEditModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
      id="asda"
    >
      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => setEditModalOpen(false)}><ArrowLeft /></button>
        <span>Edit Request</span>
        <button onClick={(e) => setEditModalOpen(false)}><X /></button>
      </DialogTitle>
      <DialogContent>
        <EditMaintenanceForm setAddModalOpen={setEditModalOpen.bind(this)} setLoading={setLoading.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setRequestData={setRequestData.bind(this)} requestData={requestData} />
      </DialogContent>
    </Dialog>

    <Dialog
      open={delModalOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setDelModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">Are you sure you want to delete the Maintenance Record?<button onClick={(e) => setDelModalOpen(false)}><X /></button></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">The Maintenance Record will be removed immediately.You can't undo this action.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="CancelPopupBU" onClick={(e) => delConfirm()}>Delete</Button>
        <Button className="SubmitPopupBU" onClick={(e) => setDelModalOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={archiveModalOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setArchiveModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">Are you sure you want to {requestData?.is_archived ? "unarchive" : "archive"} the Maintenance Record?<button onClick={(e) => setArchiveModalOpen(false)}><X /></button></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">{requestData?.is_archived ? "The Maintenance Record will be unarchived immediately.You can archive it at any time." : "The Maintenance Record will be archived immediately.You can unarchive it at any time."}</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="CancelPopupBU" onClick={(e) => archiveConfirm()}>{requestData?.is_archived ? "Unarchive" : "Archive"}</Button>
        <Button className="SubmitPopupBU" onClick={(e) => setArchiveModalOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />

    <Footer />
  </React.Fragment>);
};

export default withAuthenticationRequired(MaintenanceRequestDetails, {
  onRedirecting: () => <Loader />,
});