import { useEffect, useState } from "react";
import styles from "../dashboard.module.css";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { DashCardLoader } from "../../../../components/LoaderC";

const Ruquests = ({ selectedProperty, filterDateRange }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [requestList, setRequestList] = useState([]);
    const [openRequestCount, setOpenRequestCount] = useState(0);
    const [contentLoading, setContentLoading] = useState(true);

    useEffect(() => {
        const fetchData = async (pId) => {
            setContentLoading(true);
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            let params = {};

            if (filterDateRange?.start && filterDateRange?.end) {
                params = { start: filterDateRange?.start, end: filterDateRange?.end };
            }

            if (pId > 0) {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-request-list/" + pId, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: params
                }).then((response) => {
                    setContentLoading(false);
                    setRequestList(response.data.data?.list);
                    setOpenRequestCount(response.data.data?.open_requests_no);
                }).catch((error) => {
                    setContentLoading(false);
                    setRequestList([]);
                    setOpenRequestCount(0);
                });
            } else {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-request-list", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: params
                }).then((response) => {
                    setContentLoading(false);
                    setRequestList(response.data.data?.list);
                    setOpenRequestCount(response.data.data?.open_requests_no);
                }).catch((error) => {
                    setContentLoading(false);
                    setRequestList([]);
                    setOpenRequestCount(0);
                });
            }
        }

        if (selectedProperty > 0) {
            fetchData(selectedProperty);
        } else {
            fetchData(0);
        }
    }, [selectedProperty, filterDateRange, getAccessTokenSilently]);

    return (<div className={`${styles.CardDivTwo} ${styles.CardReq}`}>
        <div className={`${styles.IfnoCard}`}>
            <div className={`${styles.InsideCard}`}>


                {contentLoading && (<div className={`${styles.LoaderDash2}`}>
                    <DashCardLoader />
                </div>)}

                {!contentLoading && (<>
                    <div className={`${styles.HeadFlex}`}>
                        <h6 className={`${styles.InsideCardTitle2}`}>Maintenance {openRequestCount > 0 && <span>{openRequestCount} Open</span>}</h6>
                        <Link to="/cases/maintenance-request" className={`${styles.ViewAllBU}`}>View All</Link>
                    </div>
                    <div className={`${styles.ScrollCard}`}>
                        {requestList?.map((item) => {
                            return (<Link to={"/cases/maintenance-request/details/" + item?.id} className={`${styles.CaseCard}`} key={item?.id}>
                                <div className={`${styles.CaseCardTitle}`}>
                                    <div className={`${styles.CaseCardLeftSec}`}>
                                        <p className={`${styles.TitleText}`}>
                                            <Tooltip title={item?.title} placement="bottom-start">
                                                <span className={`${styles.NoWrap}`}>{item?.title}</span>
                                            </Tooltip>
                                            <span>#{item?.request_no}</span>
                                        </p>
                                        <p className={`${styles.TitleName}`}>{item?.property_name}:&nbsp;{item?.apt_name}, {item?.tenant_name}</p>
                                    </div>
                                    <div className={`${styles.CardPillSec}`}>
                                        <span className={item?.current_status === 0 ? `${styles.StatusPill} ${styles.Open}` : item?.current_status === 1 ? `${styles.StatusPill} ${styles.Yellow}` : item?.current_status === 2 ? `${styles.StatusPill} ${styles.Green}` : `${styles.StatusPill} ${styles.Red}`}>{item?.status_text}</span>
                                        <p className={`${styles.DateText}`}>{item?.submitted_date}</p>
                                    </div>
                                </div>
                            </Link>);
                        })}

                        {requestList?.length === 0 && (<div className={`${styles.NoDataSec}`}>
                            <div className={`${styles.NoDataIMG}`}><img src="/static/img/no-request.png" alt="" /></div>
                            <p className={`${styles.NoDataText}`}>No worries here!</p>
                            <p className={`${styles.NoDataTextSub}`}>Maintenance List is Empty.</p>
                        </div>)}
                    </div>
                </>)}
            </div>
        </div>
    </div>);
}

export default Ruquests;