import React, { useEffect, useState } from "react";
import styles from "../prospectdetails.module.css";
import { Calendar, Check, DollarSign, Edit, FileText, Image, Save, Trash2, X } from "react-feather";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../../components/Loader";
import axios from "axios";
import { toast } from "react-smart-toaster";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Select from "react-select";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { AddLogCallAPI } from "../../../components/AddLogs";
import UploaderComponent from "./UploaderComponent";
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

const brandColor = "#cccccc";
const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? brandColor : base.borderColor,
    "&:hover": {
      borderColor: state.isFocused ? brandColor : base.borderColor,
    },
  }),
};

const employmentsArr = [
  { value: "Full-Time", label: "Full-Time" },
  { value: "Part-Time", label: "Part-Time" },
];

const ProspectTab = (props) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = React.useState("");
  const [selectedApartment, setselectedApartment] = React.useState(null);
  const [StateList, setStateList] = React.useState({ data: [], loading: false });
  const [stateSelect1, setStateSelect1] = React.useState(null);
  const [isRename, setIsRename] = React.useState({ itemId: 0, error: false, value: "" });
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [fileDeleteId, setFileDeleteId] = React.useState(0);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    async function fetchState() {
      await axios.get(process.env.REACT_APP_PROPERTY_API_URL + "v1/states").then((response) => {
        setStateList({ data: response.data.data, loading: false });

        response.data.data.map((e) => {
          if (e.value === props?.applicantData?.state) {
            setStateSelect1(e);
          }
          return true;
        });
      }).catch((error) => {
        setStateList({ data: [], loading: true });
      });
    }

    fetchState();
  }, [props?.applicantData]);

  useEffect(() => {
    setselectedApartment({ label: props?.apartment?.apt_no + " - " + props?.apartment?.apt_type + " / " + props?.apartment?.bathroom + " Bath", value: props?.apartment?.id, type: props?.apartment?.apt_type });
  }, [props?.apartment]);

  const fileRenameFormOpen = (itemId, itemTitle, event) => {
    event.stopPropagation();
    itemTitle = itemTitle.replace(/\.[^/.]+$/, "");
    setIsRename({ itemId: itemId, error: false, value: itemTitle });
  };

  const fileRenameSave = async () => {
    if (isRename?.value === "") {
      setIsRename({ ...isRename, error: true });
    } else {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      let logData = {};
      setLoading(true);
      const postData = { file_name: isRename?.value };
      let applicantId = props?.applicantData?.id;
      return axios.put(process.env.REACT_APP_APPLICANT_API_URL + "v1/applicants/" + applicantId + "/files/" + isRename?.itemId, postData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setLoading(false);

        props?.setSnackbarMsg(response.data.message);
        props?.setSnackbarOpen(true);

        let files = response.data.data;

        let allFiles = props?.applicantData?.files.map((el) =>
          el.id === isRename?.itemId
            ? { ...el, file_name: files?.file_name }
            : el
        );

        props.updateFileList(allFiles, props?.applicant_index);
        setIsRename({ itemId: 0, itemType: "", error: false, value: "" });

        logData = {
          title: "Applicant file renamed",
          description: [
            "Name: " + props?.applicantData?.full_name,
            "Email: " + props?.applicantData?.email,
            "Phone: " + props?.applicantData?.phone,
            "File: " + files?.file_name,
          ],
        };
        AddLogCallAPI(logData, token);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setLoading(false);
      });
    }
  };

  const deleteFile = async () => {
    let fileDeleteIdTemp = fileDeleteId;
    setIsModalOpen(false);
    setFileDeleteId(0);

    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    let logData = {};
    setLoading(true);

    let applicantId = props?.applicantData?.id;

    return axios.delete(process.env.REACT_APP_APPLICANT_API_URL + "v1/applicants/" + applicantId + "/files/" + fileDeleteIdTemp, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {

      props?.setSnackbarMsg(response.data.message);
      props?.setSnackbarOpen(true);

      setLoading(false);

      let delFile = props?.applicantData?.files.filter(
        (item) => item.id === fileDeleteIdTemp
      );
      let allFiles = props?.applicantData?.files.filter(
        (item) => item.id !== fileDeleteIdTemp
      );

      props.updateFileList(allFiles, props?.applicant_index);

      logData = {
        title: "Applicant file deleted",
        description: [
          "Name: " + props?.applicantData?.full_name,
          "Email: " + props?.applicantData?.email,
          "Phone: " + props?.applicantData?.phone,
          "File: " + delFile[0].file_name,
        ],
      };
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const onFileUploadSuccess = (files) => {
    let logData = {};

    let allFiles = props?.applicantData?.files;
    allFiles = [files].concat(allFiles);

    props.updateFileList(allFiles, props?.applicant_index);

    setLoading(false);

    logData = {
      title: "Applicant file uploaded",
      description: [
        "Name: " + props?.applicantData?.full_name,
        "Email: " + props?.applicantData?.email,
        "Phone: " + props?.applicantData?.phone,
        "File Name: " + files?.file_name,
      ],
    };

    props?.setSnackbarMsg('Files uploaded successfully.');
    props?.setSnackbarOpen(true);

    AddLogCallAPI(logData, this.token);
  };

  const openFile = (item) => {
    window.open(item?.file_url, '_blank')
  }

  return (<>
    {loading && <Loader />}
    <form>
      <div className="FormAccording ProspectTask Theme">
        <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <div className={`${styles.FormAccoHead}`}>
              <p className={`${styles.FormAccoHeadTitle}`}>Basic information</p>
              <div className={(props?.applicantData?.is_personal_info_submitted === 1) ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`}>
                <Check />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className={`${styles.CussAccoRow}`}>
            <div className={`${styles.AccoFormSec}`}>
              <div className={`${styles.FormGroupFull} CusSelect`}>
                <label className={`${styles.FormLabel}`}>Apt #</label>
                <div className={`${styles.Width100}`}>
                  <Select options={[]} styles={customStyles} value={selectedApartment} isDisabled={true} />
                </div>
              </div>

              <div className={`${styles.ToggleBUHalf}`}>
                <label className={`${styles.FormLabel}`}>Specify the number of applicants</label>
                <button className={`${styles.AppliFormToggleBU} ${styles.Active}`} disabled>
                  {props?.no_of_applicants}
                </button>
              </div>
              {props?.applicantData?.move_in_date !== "" && props?.applicantData?.move_in_date !== "null" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Desired move-in date</label>
                <div className={`${styles.Width100}`}>
                  <div className={`AppliAccoFormDate ${styles.AppliAccoFormDateTenantDE}`}>
                    <DatePicker
                      label="Basic example"
                      format="MM/dd/yyyy"
                      value={props?.applicantData?.formatted_move_in_date}
                      animateYearScrolling
                      disabled
                    />
                    <p className={`${styles.CalendarIcon}`}><Calendar /></p>
                  </div>
                </div>
              </div>)}
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
            <div className={`${styles.FormAccoHead}`}>
              <p className={`${styles.FormAccoHeadTitle}`}>Personal information</p>
              <div className={(props?.applicantData?.is_personal_info_submitted === 1) ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`}>
                <Check />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className={`${styles.CussAccoRow}`}>
            <div className={`${styles.AccoFormSec}`}>
              {props?.applicantData?.first_name !== "" && props?.applicantData?.first_name !== "null" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>First name</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.first_name} type="text" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.middle_name !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Middle initial <span>Optional</span></label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.middle_name} type="text" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.last_name !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Last name</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.last_name} type="text" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.birth_date !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Birth date</label>
                <div className={`${styles.Width100}`}>
                  <div className={`AppliAccoFormDate ${styles.AppliAccoFormDateTenantDE}`}>
                    <DatePicker
                      label="Basic example"
                      format="MM/dd/yyyy"
                      value={props?.applicantData?.birth_date}
                      animateYearScrolling
                      disabled
                    />
                    <p className={`${styles.CalendarIcon}`}><Calendar /></p>
                  </div>
                </div>
              </div>)}
              {props?.applicantData?.driver_license !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Driver's license or ID #</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.driver_license} type="text" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.social_security_number !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Social security number</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder="*********" type="number" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.email !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Email address</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.email} type="email" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.phone !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Phone </label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.phone} type="number" styles={customStyles} disabled />
                </div>
              </div>)}
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
            <div className={`${styles.FormAccoHead}`}>
              <p className={`${styles.FormAccoHeadTitle}`}>Address</p>
              <div className={(props?.applicantData?.is_address_submitted === 1) ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`}>
                <Check />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={`${styles.AccoFormSec}`}>
              {props?.applicantData?.address !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Current street address</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.address} type="text" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.address2 !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Street address <span>Optional</span></label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.address2} type="text" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.city !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>City </label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.city} type="text" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.state !== "" && (<div className={`${styles.FormGroupHalf} CusSelect`}>
                <label className={`${styles.FormLabel}`}>State</label>
                <div className={`${styles.Width100}`}>
                  <Select options={StateList?.data} styles={customStyles} value={stateSelect1} isDisabled={true} />
                </div>
              </div>)}
              {props?.applicantData?.zip !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Zip code </label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} defaultValue={props?.applicantData?.zip} type="number" styles={customStyles} disabled />
                </div>
              </div>)}
            </div>
          </AccordionDetails>
        </Accordion>

        {((props?.applicantData?.landlord_name !== "" && props?.applicantData?.landlord_name !== null) || (props?.applicantData?.landlord_phone_no !== "" && props?.applicantData?.landlord_phone_no !== null) || (props?.applicantData?.reason_for_moving !== "" && props?.applicantData?.reason_for_moving !== null) || (props?.applicantData?.who_live_with_you !== "" && props?.applicantData?.who_live_with_you !== null) || (props?.applicantData?.long_lived_current_addrs !== "" && props?.applicantData?.long_lived_current_addrs !== null) || (props?.applicantData?.current_rent !== "" && props?.applicantData?.current_rent !== null)) && (<Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
            <div className={`${styles.FormAccoHead}`}>
              <p className={`${styles.FormAccoHeadTitle}`}>Rental Information</p>
              <div className={(props?.applicantData?.is_rental_info_submitted === 1) ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`}>
                <Check />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={`${styles.AccoFormSec}`}>
              {props?.applicantData?.landlord_name !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Name of present landlord</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.landlord_name} type="text" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.landlord_phone_no !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Phone number of present landlord</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.landlord_phone_no} type="number" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.reason_for_moving !== "" && (<div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Reason for moving</label>
                <div className={`${styles.Width100}`}>
                  <textarea name={"reason_for_moving"} placeholder={props?.applicantData?.reason_for_moving} className={`${styles.formControlTextarea}`} disabled />
                  <p className={`${styles.FormFieldMessage}`}>200 characters remaining</p>
                </div>
              </div>)}
              {props?.applicantData?.who_live_with_you !== "" && (<div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Who else will be living with you? Please provide their first name, last name, birth date and relationship to you</label>
                <div className={`${styles.Width100}`}>
                  <textarea name={"who_live_with_you"} placeholder={props?.applicantData?.who_live_with_you} className={`${styles.formControlTextarea}`} disabled />
                </div>
              </div>)}
              {props?.applicantData?.long_lived_current_addrs !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>How many months have you lived at present address?</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.long_lived_current_addrs} type="number" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.props?.applicantData?.current_rent !== "" && (<div className={`${styles.FormGroupHalf} ${styles.Dollar}`}>
                <label className={`${styles.FormLabel}`}>Current rent per month in USD</label>
                <div className={`${styles.Width100}`}>
                  <p className={`${styles.AccoDollarSign}`}><DollarSign /></p>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.formatted_current_rent} type="number" styles={customStyles} disabled />
                </div>
              </div>)}
            </div>
          </AccordionDetails>
        </Accordion>)}

        {((props?.applicantData?.employement !== "" && props?.applicantData?.employement !== null) || (props?.applicantData?.employer_name !== "" && props?.applicantData?.employer_name !== null) || (props?.applicantData?.employer_address !== "" && props?.applicantData?.employer_address !== null) || (props?.applicantData?.years_employed !== "" && props?.applicantData?.years_employed !== null) || (props?.applicantData?.job_title !== "" && props?.applicantData?.job_title !== null) || (props?.applicantData?.income_per_year !== "" && props?.applicantData?.income_per_year !== null) || (props?.applicantData?.hire_date !== "" && props?.applicantData?.hire_date !== "null")) && (<Accordion expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
            <div className={`${styles.FormAccoHead}`}>
              <p className={`${styles.FormAccoHeadTitle}`}>Employment</p>
              <div className={(props?.applicantData?.is_employement_submitted === 1) ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`}>
                <Check />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={`${styles.AccoFormSec}`}>
              {props?.applicantData?.employement !== "" && (<div className={`${styles.FormGroupHalf} CusSelect`}>
                <label className={`${styles.FormLabel}`}>Employment</label>
                <div className={`${styles.Width100}`}>
                  <Select options={employmentsArr} styles={customStyles} defaultValue={{ label: props?.applicantData?.employement, value: props?.applicantData?.employement }} isDisabled={true} />
                </div>
              </div>)}
              {props?.applicantData?.employer_name !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Employer name</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.employer_name} type="text" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.employer_address !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Employer address</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.employer_address} type="text" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.years_employed !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Years employed</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.years_employed} type="number" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.job_title !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Job title</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.job_title} type="text" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.income_type !== "" && (<div className={`${styles.FormGroupHalf} CusSelect`}>
                <label className={`${styles.FormLabel}`}>Income Type</label>
                <div className={`${styles.Width100}`}>
                  <Select options={[{ value: "Salary", label: "Salary" }, { value: "Voucher", label: "Voucher" }]} styles={customStyles} defaultValue={{ label: props?.applicantData?.income_type, value: props?.applicantData?.income_type }} isDisabled={true} />
                </div>
              </div>)}
              {props?.applicantData?.income_type === "Salary" && (<div className={`${styles.FormGroupHalf} ${styles.Dollar}`}>
                <label className={`${styles.FormLabel}`}>Income per year in USD</label>
                <div className={`${styles.Width100}`}>
                  <p className={`${styles.AccoDollarSign}`}><DollarSign /></p>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.formatted_income_per_year} type="number" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.income_type === "Voucher" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Amount</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.voucher_amount} type="text" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.hire_date !== "" && props?.applicantData?.hire_date !== "null" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>Hire date</label>
                <div className={`${styles.Width100}`}>
                  <div className={`AppliAccoFormDate ${styles.AppliAccoFormDateTenantDE}`}>
                    <DatePicker
                      label="Basic example"
                      format="MM/dd/yyyy"
                      value={props?.applicantData?.hire_date}
                      animateYearScrolling
                      disabled
                    />
                    <p className={`${styles.CalendarIcon}`}><Calendar /></p>
                  </div>
                </div>
              </div>)}
            </div>
          </AccordionDetails>
        </Accordion>)}

        <Accordion expanded={expanded === "panel6"} onChange={handleChange("panel6")}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
            <div className={`${styles.FormAccoHead}`}>
              <p className={`${styles.FormAccoHeadTitle}`}>Other information</p>
              <div className={(props?.applicantData?.is_other_info_submitted === 1) ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`}>
                <Check />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={`${styles.AccoFormSec}`}>
              {props?.applicantData?.ever_evicted !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>How many evictions have been filed upon you?</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.ever_evicted} type="number" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.ever_case_filed !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>How many cases have been filled with you in housing court?</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.ever_case_filed} type="number" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.ever_broken_lease !== "" && (<div className={`${styles.ToggleBUHalf}`}>
                <label className={`${styles.FormLabel}`}>Have you ever broken a lease?</label>
                <div className={`${styles.CusRadioRow}`}>
                  <label className={`${styles.CusRadio}`}>
                    <input type="radio" name="ever_broken_lease" checked={props?.applicantData?.ever_broken_lease === "Yes"} disabled />
                    <div className={`${styles.checkmark}`}>Yes</div>
                  </label>
                  <label className={`${styles.CusRadio}`}>
                    <input type="radio" name="ever_broken_lease" checked={props?.applicantData?.ever_broken_lease === "No"} disabled />
                    <div className={`${styles.checkmark}`}>No</div>
                  </label>
                </div>
              </div>)}
              {props?.applicantData?.credit_score !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>What is your credit score?</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.credit_score} type="number" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.have_pets !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>How many pets do you have?</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.have_pets} type="number" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.ever_had_bedbugs !== "" && (<div className={`${styles.ToggleBUHalf}`}>
                <label className={`${styles.FormLabel}`}>Have you ever had bed bugs?</label>
                <div className={`${styles.CusRadioRow}`}>
                  <label className={`${styles.CusRadio}`}>
                    <input type="radio" name="ever_had_bedbugs" checked={props?.applicantData?.ever_had_bedbugs === "Yes"} disabled />
                    <div className={`${styles.checkmark}`}>Yes</div>
                  </label>
                  <label className={`${styles.CusRadio}`}>
                    <input type="radio" name="ever_had_bedbugs" checked={props?.applicantData?.ever_had_bedbugs === "No"} disabled />
                    <div className={`${styles.checkmark}`}>No</div>
                  </label>
                </div>
              </div>)}
              {props?.applicantData?.ever_had_bankruptcy !== "" && (<div className={`${styles.ToggleBUHalf}`}>
                <label className={`${styles.FormLabel}`}>Have you ever filed for bankruptcy?</label>
                <div className={`${styles.CusRadioRow}`}>
                  <label className={`${styles.CusRadio}`}>
                    <input type="radio" name="ever_had_bankruptcy" checked={props?.applicantData?.ever_had_bankruptcy === "Yes"} disabled />
                    <div className={`${styles.checkmark}`}>Yes</div>
                  </label>
                  <label className={`${styles.CusRadio}`}>
                    <input type="radio" name="ever_had_bankruptcy" checked={props?.applicantData?.ever_had_bankruptcy === "No"} disabled />
                    <div className={`${styles.checkmark}`}>No</div>
                  </label>
                </div>
              </div>)}
              {props?.applicantData?.do_you_smoke !== "" && (<div className={`${styles.ToggleBUHalf}`}>
                <label className={`${styles.FormLabel}`}>Do you smoke?</label>
                <div className={`${styles.CusRadioRow}`}>
                  <label className={`${styles.CusRadio}`}>
                    <input type="radio" name="do_you_smoke" checked={props?.applicantData?.do_you_smoke === "Yes"} disabled />
                    <div className={`${styles.checkmark}`}>Yes</div>
                  </label>
                  <label className={`${styles.CusRadio}`}>
                    <input type="radio" name="do_you_smoke" checked={props?.applicantData?.do_you_smoke === "No"} disabled />
                    <div className={`${styles.checkmark}`}>No</div>
                  </label>
                </div>
              </div>)}
              {props?.applicantData?.own_vehicles !== "" && (<div className={`${styles.FormGroupHalf}`}>
                <label className={`${styles.FormLabel}`}>How many vehicles do you own?</label>
                <div className={`${styles.Width100}`}>
                  <input className={`${styles.formControl}`} placeholder={props?.applicantData?.own_vehicles} type="number" styles={customStyles} disabled />
                </div>
              </div>)}
              {props?.applicantData?.additional_information !== "" && props?.applicantData?.additional_information !== null && (<div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Additional information <span>OPTIONAL</span></label>
                <p className={`${styles.FormLabelSmall}`}>Please use this space for additional information, comments or explanations.</p>
                <div className={`${styles.Width100}`}>
                  <textarea name={"additional_information"} placeholder={props?.applicantData?.additional_information} className={`${styles.formControlTextarea}`} disabled />
                </div>
              </div>)}
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === "panel7"} onChange={handleChange("panel7")}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
            <div className={`${styles.FormAccoHead}`}>
              <p className={`${styles.FormAccoHeadTitle}`}>Upload files</p>
              <div className={(props?.applicantData?.is_upload_files === 1) ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`}>
                <Check />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={`${styles.AccoFormSec}`}>
              <div className={`${styles.DivFull}`}>
                <p className={`${styles.FileUpTitle}`}>Please upload all the following supporting documents:</p>
                <ul className={`${styles.FileUpList}`}>
                  <li>Copy of credit report</li>
                  <li>Recent W2s</li>
                  <li>Last 4 Pay stubs and most recent Bank Statement</li>
                  <li>Driver’s License</li>
                </ul>
              </div>
              <div className={`${styles.FileIMGDiv}`}>
                {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect:update") > -1 && (<UploaderComponent
                  allowedExtensions={".pdf,.png,.jpg,.jpeg"}
                  isMuliple={false}
                  setLoading={setLoading.bind(this)}
                  uploadUrl={process.env.REACT_APP_APPLICANT_API_URL + "v1/applicants/" + props?.applicantData?.id + "/files/upload"}
                  onUploadLocal={onFileUploadSuccess.bind(this)}
                />)}
                <div className={`${styles.FileListRow}`}>
                  {props?.applicantData?.files.map((itemFile, indexF) => {
                    return isRename?.itemId === itemFile.id ? (<div className={`${styles.UpFileList}`} key={indexF}>
                      <div className={`${styles.UpFileListUnder} ${styles.Active}`}>
                        <div className={`${styles.FileName}`}>
                          <FileText />{" "}
                          <TextField hiddenLabel autoFocus error={isRename?.error} variant="outlined" className={`${styles.FileRenameField}`} defaultValue={isRename.value} onChange={(e) => setIsRename({ ...isRename, value: e.target.value })} />
                        </div>
                        <p className={`${styles.FileSize}`}>{moment(itemFile?.created_at).format("MM/DD/YYYY LT")}</p>
                        {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect:update") > -1 && <div className={`${styles.FileListRight}`}>
                          <Button className={`${styles.FileListRename}`} onClick={fileRenameSave.bind(this)}><Save /></Button>
                          <Button className={`${styles.FileListCancel}`} onClick={(e) => setIsRename({ itemId: 0, error: false, value: "" })}><X /></Button>
                        </div>}
                      </div>
                    </div>) : (<div className={`${styles.UpFileList}`} key={indexF} onClick={(e) => openFile(itemFile)}>
                      <div className={`${styles.UpFileListUnder}`}>
                        <p className={`${styles.FileName}`}>
                          {itemFile.file_type === "pdf" ? (<FileText />) : (<Image />)}{" "}
                          <span>{itemFile.file_name}</span>
                        </p>
                        <p className={`${styles.FileSize}`}>{moment(itemFile?.created_at).format("MM/DD/YYYY LT")}</p>
                        <div className={`${styles.FileListRight}`}>
                          <Button className={`${styles.FileListRename}`} onClick={fileRenameFormOpen.bind(this, itemFile.id, itemFile.file_name)}><Edit /></Button>
                          <Button className={`${styles.FileListDelete}`} onClick={(e) => { e.stopPropagation(); setIsModalOpen(true); setFileDeleteId(itemFile?.id); }}><Trash2 /></Button>
                        </div>
                      </div>
                    </div>);
                  })}
                </div>
                <Dialog open={isModalOpen} fullWidth maxWidth="sm" onClose={(e) => { setIsModalOpen(false); setFileDeleteId(0); }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="NewPopUp">
                  <DialogTitle id="alert-dialog-title">
                    Delete Document
                    <button onClick={(e) => { setIsModalOpen(false); setFileDeleteId(0); }}><X /></button>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description" className="ModalFormGroup" style={{ marginBottom: "0px", padding: "25px 0" }}>
                      <label className="PopupBodyText">Are you sure you want to delete the uploaded document? The uploaded document will be removed immediately. You
                        can't undo this action.</label>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button className="CancelPopupBU" onClick={deleteFile.bind(this)}>Delete</Button>
                    <Button onClick={(e) => { setIsModalOpen(false); setFileDeleteId(0); }} className="SubmitPopupBU">Cancel</Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === "panel8"} onChange={handleChange("panel8")}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
            <div className={`${styles.FormAccoHead}`}>
              <p className={`${styles.FormAccoHeadTitle}`}>Signature</p>
              <div className={(props?.applicantData?.is_term_submitted === 1) ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`}>
                <Check />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={`${styles.AccoFormSec}`}>
              <div className={`${styles.SignarureBody}`}>
                {props?.applicantData?.signature && props?.applicantData?.signature !== "" && (<div className={`${styles.UploadImgSign}`}>
                  <img src={props?.applicantData?.signature} alt="applicantImage" />
                </div>)}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </form>
  </>
  );
};

export default ProspectTab;
